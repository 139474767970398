export default {
  "weekday": {
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понеділок"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вівторок"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середа"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четвер"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["П'ятниця"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Субота"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неділя"])}
  },
  "month": {
    "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Січень"])},
    "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лютий"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Березень"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квітень"])},
    "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Травень"])},
    "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Червень"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Липень"])},
    "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Серпень"])},
    "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вересень"])},
    "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жовтень"])},
    "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Листопад"])},
    "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Грудень"])}
  },
  "schedule": {
    "working_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Графік роботи"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відчинено"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зачинено"])},
    "hour_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["год"])},
    "minute_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["хв"])},
    "T_until_closing": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " до закриття"])},
    "T_before_opening": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("time")), " до відкриття"])}
  },
  "unit": {
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["г"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кг"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["л"])},
    "ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мл"])},
    "cm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["см"])},
    "pc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["шт"])}
  },
  "error": {
    "back_to_main": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися назад"])}
    },
    "reload_page": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перезавантажити сторінку"])}
    },
    "something_went_wrong": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щось пішло не так..."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Під час завантаження даних із сервера сталася помилка."])}
    }
  },
  "login": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["З поверненням!"])},
    "register_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ще не зареєстровані?"])},
    "login_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввійти"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошта"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
  },
  "register": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вперше тут?"])},
    "login_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вже зареєстровані?"])},
    "register_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошта"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "password_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтверження Паролю"])}
  },
  "preview": {
    "navbar": {
      "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
      "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
      "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія"])},
      "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове замовлення"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заклад"])},
      "street_and_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вулиця, аддрес"])}
    },
    "restaurant": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження закладу"])},
      "loading_menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження меню"])},
      "loading_reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження відгуків"])},
      "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню"])},
      "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заклад"])}
    },
    "restaurants": {
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження закладів"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть заклад"])},
      "select_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати"])}
    },
    "menu": {
      "switcher": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список меню"])}
      },
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження меню"])},
      "loading_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження столів та приміщень"])},
      "loading_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження страв"])},
      "loading_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження послуг"])},
      "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, список порожній"])}
    },
    "space": {
      "floor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поверх"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])}
    },
    "item": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати"])}
    },
    "order": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нема доданих позицій"])},
      "for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["за"])},
      "item_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позиція"])},
      "item_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позиції"])},
      "item_plural2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позиції"])},
      "item_plural5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["позицій"])},
      "space_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бронювання"])},
      "space_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бронювань"])},
      "space_plural2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бронювання"])},
      "space_plural5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бронювань"])},
      "ticket_singular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["квиток"])},
      "ticket_plural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["квитки"])},
      "ticket_plural2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["квитки"])},
      "ticket_plural5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["квитків"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження замовлення"])},
      "loading_spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження столів та приміщень"])},
      "loading_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження страв"])},
      "loading_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження послуг"])},
      "new_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нове замовлення"])},
      "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти замовлення"])},
      "was_successfully_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Замовлення було успішно збережено"])},
      "an_error_occurred_while_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При збереженні замовлення сталася помилка"])},
      "errors": {
        "serve_at": {
          "invalid": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Неправильний формат часу подачі страви: ", _interpolate(_named("time")), ". Приклади правильного формату: 8:30, 11:40, 16:05"])}
        }
      }
    },
    "tip": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чайові"])},
      "leave_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити чайові"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])},
      "for": {
        "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офіціанту або за обслуговування, їжу, чистоту"])},
        "waiter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Офіціанту"])},
        "waiter_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити чайові офіціанту"])},
        "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За обслуговування"])},
        "service_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити чайові за якість обслуговування"])},
        "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За їжу"])},
        "food_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити чайові кухарям"])},
        "cleanness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За чистоту"])},
        "cleanness_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залишити чайові працівникам, які підтримують чистоту"])}
      },
      "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано ", _interpolate(_named("count")), " з ", _interpolate(_named("total")), " офіціантів"])},
      "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано всіх офіціантів (", _interpolate(_named("count")), ")"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження офіціантів"])},
      "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити ще..."])},
      "errors": {
        "amount": {
          "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Мінімальна сума: ", _interpolate(_named("amount"))])}
        }
      },
      "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, список порожній"])}
    }
  },
  "product": {
    "serving_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час подачі"])}
  },
  "reviews": {
    "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, список порожній"])},
    "click_to_leave_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натисніть, щоб залишити відгук"])},
    "review_restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оцініть заклад"])},
    "store_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти відгук"])},
    "your_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш відгук"])},
    "your_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше ім'я"])},
    "select_score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть оцінку"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити ще..."])},
    "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано ", _interpolate(_named("count")), " з ", _interpolate(_named("total")), " відгуків"])},
    "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано всі відгуки (", _interpolate(_named("count")), ")"])},
    "errors": {
      "ip": {
        "failed_to_resolve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося визначити вашу IP-адресу, тому ми не зможемо зберегти ваш відгук."])}
      },
      "name": {
        "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть ваше ім'я"])},
        "min2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я має містити щонайменше 2 символи"])}
      },
      "description": {
        "min5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відгук має містити щонайменше 5 символів"])}
      }
    }
  },
  "banquet": {
    "banquet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкет"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва банкету"])},
    "price_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
    "actual_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фактична вартість"])},
    "advance_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завдаток"])},
    "advance_amount_payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спосіб оплати завдатку"])},
    "marks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позначки"])},
    "birthday_club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday club"])},
    "photographer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотограф"])},
    "adults_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість дорослих"])},
    "adult_ticket_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціна квитка для дорослого"])},
    "children_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кількість дітей"])},
    "child_ticket_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціна квитка для дитини"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити банкет"])},
    "store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти банкет"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження банкета"])},
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментар"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарі"])},
    "create_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати коментар"])},
    "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Більше деталей"])},
    "was_successfully_saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкет було успішно збережено"])},
    "an_error_occurred_while_saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При збереженні банкету сталася помилка"])},
    "more": {
      "bill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])}
    },
    "payment_method": {
      "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картка"])},
      "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Готівка"])}
    },
    "bill": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рахунок"])},
      "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роздрукувати"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
      "restaurant_and_client_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дані закладу та клієнта"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коментарі"])},
      "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню"])},
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теги"])},
      "sections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секції"])},
      "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квитки"])},
      "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервіси"])},
      "spaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Столи/кімнати"])}
    },
    "state": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий"])},
      "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтверджений"])},
      "postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відкладений"])},
      "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відмінений"])},
      "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершений"])}
    },
    "time": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Початок"])},
      "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кінець"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])}
    },
    "calendar": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])}
    },
    "customer": {
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клієнт"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошта"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрити"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створити"])},
      "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
      "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, список порожній"])},
      "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження клієнтів"])},
      "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити ще..."])},
      "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано ", _interpolate(_named("count")), " з ", _interpolate(_named("total")), " клієнтів"])},
      "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано всіх клієнтів (", _interpolate(_named("count")), ")"])},
      "errors": {
        "required": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть ім'я."])},
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть прізвище."])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть номер телефону."])},
          "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть пошту."])},
          "phone_or_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть номер телефону або пошту."])}
        },
        "min": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я має містити щонайменше 2 символи."])},
          "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прізвище має містити щонайменше 2 символи."])},
          "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону має містити щонайменше 10 символів."])}
        },
        "email_rules": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пошта має містити ", _interpolate(_named("sign")), " та не має містити пробілів."])},
        "phone_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефону має починатися з + та містити щонайменше 10 символів."])}
      }
    },
    "errors": {
      "required": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть назву."])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть дату."])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть час початку та кінця."])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виберіть клієнта."])}
      },
      "min": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва має містити щонайменше 2 символи."])}
      }
    }
  },
  "history": {
    "unfortunately_list_is_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На жаль, список порожній"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантаження банкетів"])},
    "load_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завантажити ще..."])},
    "showing_C_of_T": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано ", _interpolate(_named("count")), " з ", _interpolate(_named("total")), " банкетів"])},
    "showing_all_C": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показано всі банкети (", _interpolate(_named("count")), ")"])},
    "selection": {
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вибрано"])}
    },
    "filters": {
      "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Від"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По"])}
    }
  }
}