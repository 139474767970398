/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  Product,
  instanceOfProduct,
  ProductFromJSON,
  ProductFromJSONTyped,
  ProductToJSON,
} from "./Product";
import {
  ProductVariant,
  instanceOfProductVariant,
  ProductVariantFromJSON,
  ProductVariantFromJSONTyped,
  ProductVariantToJSON,
} from "./ProductVariant";

/**
 * @type AlternationAlterable
 *
 * @export
 */
export type AlternationAlterable = Product | ProductVariant;

export function AlternationAlterableFromJSON(json: any): AlternationAlterable {
  return AlternationAlterableFromJSONTyped(json, false);
}

export function AlternationAlterableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AlternationAlterable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...ProductFromJSONTyped(json, true),
    ...ProductVariantFromJSONTyped(json, true),
  };
}

export function AlternationAlterableToJSON(
  value?: AlternationAlterable | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }

  if (instanceOfProduct(value)) {
    return ProductToJSON(value as Product);
  }
  if (instanceOfProductVariant(value)) {
    return ProductVariantToJSON(value as ProductVariant);
  }

  return {};
}
