/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AttachingComment } from "./AttachingComment";
import {
  AttachingCommentFromJSON,
  AttachingCommentFromJSONTyped,
  AttachingCommentToJSON,
} from "./AttachingComment";
import type { AttachingDiscount } from "./AttachingDiscount";
import {
  AttachingDiscountFromJSON,
  AttachingDiscountFromJSONTyped,
  AttachingDiscountToJSON,
} from "./AttachingDiscount";

/**
 * Store order request service field
 * @export
 * @interface StoreOrderRequestServiceField
 */
export interface StoreOrderRequestServiceField {
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequestServiceField
   */
  serviceId: number;
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequestServiceField
   */
  amount: number;
  /**
   * Duration of the service rental in minutes.
   * @type {number}
   * @memberof StoreOrderRequestServiceField
   */
  duration: number;
  /**
   *
   * @type {Array<AttachingComment>}
   * @memberof StoreOrderRequestServiceField
   */
  comments?: Array<AttachingComment>;
  /**
   *
   * @type {Array<AttachingDiscount>}
   * @memberof StoreOrderRequestServiceField
   */
  discounts?: Array<AttachingDiscount>;
}

/**
 * Check if a given object implements the StoreOrderRequestServiceField interface.
 */
export function instanceOfStoreOrderRequestServiceField(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && "serviceId" in value;
  isInstance = isInstance && "amount" in value;
  isInstance = isInstance && "duration" in value;

  return isInstance;
}

export function StoreOrderRequestServiceFieldFromJSON(
  json: any
): StoreOrderRequestServiceField {
  return StoreOrderRequestServiceFieldFromJSONTyped(json, false);
}

export function StoreOrderRequestServiceFieldFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreOrderRequestServiceField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    serviceId: json["service_id"],
    amount: json["amount"],
    duration: json["duration"],
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(AttachingCommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(AttachingDiscountFromJSON),
  };
}

export function StoreOrderRequestServiceFieldToJSON(
  value?: StoreOrderRequestServiceField | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    service_id: value.serviceId,
    amount: value.amount,
    duration: value.duration,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(AttachingCommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(AttachingDiscountToJSON),
  };
}
