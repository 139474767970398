/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  BanquetInvoiceUrlRequest,
  BanquetInvoiceUrlResponse,
  BanquetMultipleInvoiceUrlResponse,
  MultipleInvoiceUrlRequest,
  OrderInvoiceUrlRequest,
  OrderInvoiceUrlResponse,
  OrderMultipleInvoiceUrlResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  BanquetInvoiceUrlRequestFromJSON,
  BanquetInvoiceUrlRequestToJSON,
  BanquetInvoiceUrlResponseFromJSON,
  BanquetInvoiceUrlResponseToJSON,
  BanquetMultipleInvoiceUrlResponseFromJSON,
  BanquetMultipleInvoiceUrlResponseToJSON,
  MultipleInvoiceUrlRequestFromJSON,
  MultipleInvoiceUrlRequestToJSON,
  OrderInvoiceUrlRequestFromJSON,
  OrderInvoiceUrlRequestToJSON,
  OrderInvoiceUrlResponseFromJSON,
  OrderInvoiceUrlResponseToJSON,
  OrderMultipleInvoiceUrlResponseFromJSON,
  OrderMultipleInvoiceUrlResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface BanquetInvoiceUrlOperationRequest {
  id: number;
  banquetInvoiceUrlRequest: BanquetInvoiceUrlRequest;
}

export interface BanquetMultipleInvoiceUrlRequest {
  multipleInvoiceUrlRequest: MultipleInvoiceUrlRequest;
}

export interface OrderInvoiceUrlOperationRequest {
  id: number;
  orderInvoiceUrlRequest: OrderInvoiceUrlRequest;
}

export interface OrderMultipleInvoiceUrlRequest {
  multipleInvoiceUrlRequest: MultipleInvoiceUrlRequest;
}

/**
 *
 */
export class InvoicesApi extends runtime.BaseAPI {
  /**
   * Generate url for accessing banquet\'s invoice.
   */
  async banquetInvoiceUrlRaw(
    requestParameters: BanquetInvoiceUrlOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BanquetInvoiceUrlResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling banquetInvoiceUrl."
      );
    }

    if (
      requestParameters.banquetInvoiceUrlRequest === null ||
      requestParameters.banquetInvoiceUrlRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "banquetInvoiceUrlRequest",
        "Required parameter requestParameters.banquetInvoiceUrlRequest was null or undefined when calling banquetInvoiceUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets/{id}/invoice/url`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: BanquetInvoiceUrlRequestToJSON(
          requestParameters.banquetInvoiceUrlRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BanquetInvoiceUrlResponseFromJSON(jsonValue)
    );
  }

  /**
   * Generate url for accessing banquet\'s invoice.
   */
  async banquetInvoiceUrl(
    requestParameters: BanquetInvoiceUrlOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BanquetInvoiceUrlResponse> {
    const response = await this.banquetInvoiceUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Generate url for accessing invoice for multiple banquets.
   */
  async banquetMultipleInvoiceUrlRaw(
    requestParameters: BanquetMultipleInvoiceUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BanquetMultipleInvoiceUrlResponse>> {
    if (
      requestParameters.multipleInvoiceUrlRequest === null ||
      requestParameters.multipleInvoiceUrlRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "multipleInvoiceUrlRequest",
        "Required parameter requestParameters.multipleInvoiceUrlRequest was null or undefined when calling banquetMultipleInvoiceUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets/invoice/url`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MultipleInvoiceUrlRequestToJSON(
          requestParameters.multipleInvoiceUrlRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BanquetMultipleInvoiceUrlResponseFromJSON(jsonValue)
    );
  }

  /**
   * Generate url for accessing invoice for multiple banquets.
   */
  async banquetMultipleInvoiceUrl(
    requestParameters: BanquetMultipleInvoiceUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BanquetMultipleInvoiceUrlResponse> {
    const response = await this.banquetMultipleInvoiceUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Generate url for accessing order\'s invoice.
   */
  async orderInvoiceUrlRaw(
    requestParameters: OrderInvoiceUrlOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OrderInvoiceUrlResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling orderInvoiceUrl."
      );
    }

    if (
      requestParameters.orderInvoiceUrlRequest === null ||
      requestParameters.orderInvoiceUrlRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "orderInvoiceUrlRequest",
        "Required parameter requestParameters.orderInvoiceUrlRequest was null or undefined when calling orderInvoiceUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/{id}/invoice/url`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: OrderInvoiceUrlRequestToJSON(
          requestParameters.orderInvoiceUrlRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderInvoiceUrlResponseFromJSON(jsonValue)
    );
  }

  /**
   * Generate url for accessing order\'s invoice.
   */
  async orderInvoiceUrl(
    requestParameters: OrderInvoiceUrlOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OrderInvoiceUrlResponse> {
    const response = await this.orderInvoiceUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Generate url for accessing invoice for multiple orders.
   */
  async orderMultipleInvoiceUrlRaw(
    requestParameters: OrderMultipleInvoiceUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OrderMultipleInvoiceUrlResponse>> {
    if (
      requestParameters.multipleInvoiceUrlRequest === null ||
      requestParameters.multipleInvoiceUrlRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "multipleInvoiceUrlRequest",
        "Required parameter requestParameters.multipleInvoiceUrlRequest was null or undefined when calling orderMultipleInvoiceUrl."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/invoice/url`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: MultipleInvoiceUrlRequestToJSON(
          requestParameters.multipleInvoiceUrlRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderMultipleInvoiceUrlResponseFromJSON(jsonValue)
    );
  }

  /**
   * Generate url for accessing invoice for multiple orders.
   */
  async orderMultipleInvoiceUrl(
    requestParameters: OrderMultipleInvoiceUrlRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OrderMultipleInvoiceUrlResponse> {
    const response = await this.orderMultipleInvoiceUrlRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
