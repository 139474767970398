/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Generate url for accessing invoice for multiple orders response.
 * @export
 * @interface OrderMultipleInvoiceUrlResponse
 */
export interface OrderMultipleInvoiceUrlResponse {
  /**
   *
   * @type {string}
   * @memberof OrderMultipleInvoiceUrlResponse
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof OrderMultipleInvoiceUrlResponse
   */
  message: string;
}

/**
 * Check if a given object implements the OrderMultipleInvoiceUrlResponse interface.
 */
export function instanceOfOrderMultipleInvoiceUrlResponse(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && "url" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function OrderMultipleInvoiceUrlResponseFromJSON(
  json: any
): OrderMultipleInvoiceUrlResponse {
  return OrderMultipleInvoiceUrlResponseFromJSONTyped(json, false);
}

export function OrderMultipleInvoiceUrlResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderMultipleInvoiceUrlResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: json["url"],
    message: json["message"],
  };
}

export function OrderMultipleInvoiceUrlResponseToJSON(
  value?: OrderMultipleInvoiceUrlResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
    message: value.message,
  };
}
