/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Space reservation resource object
 * @export
 * @interface SpaceReservation
 */
export interface SpaceReservation {
  /**
   *
   * @type {string}
   * @memberof SpaceReservation
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof SpaceReservation
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof SpaceReservation
   */
  spaceId: number;
  /**
   *
   * @type {Date}
   * @memberof SpaceReservation
   */
  startAt: Date;
  /**
   *
   * @type {Date}
   * @memberof SpaceReservation
   */
  endAt: Date;
  /**
   *
   * @type {number}
   * @memberof SpaceReservation
   */
  duration: number;
}

/**
 * Check if a given object implements the SpaceReservation interface.
 */
export function instanceOfSpaceReservation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "orderId" in value;
  isInstance = isInstance && "spaceId" in value;
  isInstance = isInstance && "startAt" in value;
  isInstance = isInstance && "endAt" in value;
  isInstance = isInstance && "duration" in value;

  return isInstance;
}

export function SpaceReservationFromJSON(json: any): SpaceReservation {
  return SpaceReservationFromJSONTyped(json, false);
}

export function SpaceReservationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpaceReservation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    type: json["type"],
    orderId: json["order_id"],
    spaceId: json["space_id"],
    startAt: new Date(json["start_at"]),
    endAt: new Date(json["end_at"]),
    duration: json["duration"],
  };
}

export function SpaceReservationToJSON(value?: SpaceReservation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    type: value.type,
    order_id: value.orderId,
    space_id: value.spaceId,
    start_at: value.startAt.toISOString(),
    end_at: value.endAt.toISOString(),
    duration: value.duration,
  };
}
