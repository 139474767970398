/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Comment } from "./Comment";
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
} from "./Comment";
import type { Discount } from "./Discount";
import {
  DiscountFromJSON,
  DiscountFromJSONTyped,
  DiscountToJSON,
} from "./Discount";

/**
 * Space order field resource object
 * @export
 * @interface SpaceOrderField
 */
export interface SpaceOrderField {
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SpaceOrderField
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  spaceId: number;
  /**
   *
   * @type {Date}
   * @memberof SpaceOrderField
   */
  startAt: Date;
  /**
   *
   * @type {Date}
   * @memberof SpaceOrderField
   */
  endAt: Date;
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  duration?: number;
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  discountsAmount: number;
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  discountsPercent: number;
  /**
   *
   * @type {number}
   * @memberof SpaceOrderField
   */
  discountedTotal: number;
  /**
   *
   * @type {Array<Comment>}
   * @memberof SpaceOrderField
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {Array<Discount>}
   * @memberof SpaceOrderField
   */
  discounts?: Array<Discount>;
}

/**
 * Check if a given object implements the SpaceOrderField interface.
 */
export function instanceOfSpaceOrderField(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "orderId" in value;
  isInstance = isInstance && "spaceId" in value;
  isInstance = isInstance && "startAt" in value;
  isInstance = isInstance && "endAt" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "discountsAmount" in value;
  isInstance = isInstance && "discountsPercent" in value;
  isInstance = isInstance && "discountedTotal" in value;

  return isInstance;
}

export function SpaceOrderFieldFromJSON(json: any): SpaceOrderField {
  return SpaceOrderFieldFromJSONTyped(json, false);
}

export function SpaceOrderFieldFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SpaceOrderField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    orderId: json["order_id"],
    spaceId: json["space_id"],
    startAt: new Date(json["start_at"]),
    endAt: new Date(json["end_at"]),
    duration: !exists(json, "duration") ? undefined : json["duration"],
    total: json["total"],
    discountsAmount: json["discounts_amount"],
    discountsPercent: json["discounts_percent"],
    discountedTotal: json["discounted_total"],
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(CommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(DiscountFromJSON),
  };
}

export function SpaceOrderFieldToJSON(value?: SpaceOrderField | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    order_id: value.orderId,
    space_id: value.spaceId,
    start_at: value.startAt.toISOString(),
    end_at: value.endAt.toISOString(),
    duration: value.duration,
    total: value.total,
    discounts_amount: value.discountsAmount,
    discounts_percent: value.discountsPercent,
    discounted_total: value.discountedTotal,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(CommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(DiscountToJSON),
  };
}
