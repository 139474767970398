/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AttachingComment } from "./AttachingComment";
import {
  AttachingCommentFromJSON,
  AttachingCommentFromJSONTyped,
  AttachingCommentToJSON,
} from "./AttachingComment";
import type { AttachingDiscount } from "./AttachingDiscount";
import {
  AttachingDiscountFromJSON,
  AttachingDiscountFromJSONTyped,
  AttachingDiscountToJSON,
} from "./AttachingDiscount";

/**
 * Store order request ticket field
 * @export
 * @interface StoreOrderRequestTicketField
 */
export interface StoreOrderRequestTicketField {
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequestTicketField
   */
  ticketId: number;
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequestTicketField
   */
  amount: number;
  /**
   *
   * @type {Array<AttachingComment>}
   * @memberof StoreOrderRequestTicketField
   */
  comments?: Array<AttachingComment>;
  /**
   *
   * @type {Array<AttachingDiscount>}
   * @memberof StoreOrderRequestTicketField
   */
  discounts?: Array<AttachingDiscount>;
}

/**
 * Check if a given object implements the StoreOrderRequestTicketField interface.
 */
export function instanceOfStoreOrderRequestTicketField(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "ticketId" in value;
  isInstance = isInstance && "amount" in value;

  return isInstance;
}

export function StoreOrderRequestTicketFieldFromJSON(
  json: any
): StoreOrderRequestTicketField {
  return StoreOrderRequestTicketFieldFromJSONTyped(json, false);
}

export function StoreOrderRequestTicketFieldFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreOrderRequestTicketField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ticketId: json["ticket_id"],
    amount: json["amount"],
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(AttachingCommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(AttachingDiscountFromJSON),
  };
}

export function StoreOrderRequestTicketFieldToJSON(
  value?: StoreOrderRequestTicketField | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ticket_id: value.ticketId,
    amount: value.amount,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(AttachingCommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(AttachingDiscountToJSON),
  };
}
