/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Generate url for accessing invoice for multiple orders request
 * @export
 * @interface MultipleInvoiceUrlRequest
 */
export interface MultipleInvoiceUrlRequest {
  /**
   *
   * @type {string}
   * @memberof MultipleInvoiceUrlRequest
   */
  ids?: string;
  /**
   *
   * @type {string}
   * @memberof MultipleInvoiceUrlRequest
   */
  endpoint?: MultipleInvoiceUrlRequestEndpointEnum;
}

/**
 * @export
 */
export const MultipleInvoiceUrlRequestEndpointEnum = {
  PdfMultiple: "pdfMultiple",
  ViewMultiple: "viewMultiple",
} as const;
export type MultipleInvoiceUrlRequestEndpointEnum =
  (typeof MultipleInvoiceUrlRequestEndpointEnum)[keyof typeof MultipleInvoiceUrlRequestEndpointEnum];

/**
 * Check if a given object implements the MultipleInvoiceUrlRequest interface.
 */
export function instanceOfMultipleInvoiceUrlRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function MultipleInvoiceUrlRequestFromJSON(
  json: any
): MultipleInvoiceUrlRequest {
  return MultipleInvoiceUrlRequestFromJSONTyped(json, false);
}

export function MultipleInvoiceUrlRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MultipleInvoiceUrlRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ids: !exists(json, "ids") ? undefined : json["ids"],
    endpoint: !exists(json, "endpoint") ? undefined : json["endpoint"],
  };
}

export function MultipleInvoiceUrlRequestToJSON(
  value?: MultipleInvoiceUrlRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ids: value.ids,
    endpoint: value.endpoint,
  };
}
