/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Schedule } from "./Schedule";
import {
  ScheduleFromJSON,
  ScheduleFromJSONTyped,
  ScheduleToJSON,
} from "./Schedule";

/**
 * Get restaurant's schedules response object.
 * @export
 * @interface GetSchedulesResponse
 */
export interface GetSchedulesResponse {
  /**
   *
   * @type {Array<Schedule>}
   * @memberof GetSchedulesResponse
   */
  data: Array<Schedule>;
  /**
   *
   * @type {string}
   * @memberof GetSchedulesResponse
   */
  message: string;
}

/**
 * Check if a given object implements the GetSchedulesResponse interface.
 */
export function instanceOfGetSchedulesResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function GetSchedulesResponseFromJSON(json: any): GetSchedulesResponse {
  return GetSchedulesResponseFromJSONTyped(json, false);
}

export function GetSchedulesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetSchedulesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(ScheduleFromJSON),
    message: json["message"],
  };
}

export function GetSchedulesResponseToJSON(
  value?: GetSchedulesResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ScheduleToJSON),
    message: value.message,
  };
}
