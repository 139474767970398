/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PaginationMeta } from "./PaginationMeta";
import {
  PaginationMetaFromJSON,
  PaginationMetaFromJSONTyped,
  PaginationMetaToJSON,
} from "./PaginationMeta";
import type { Restaurant } from "./Restaurant";
import {
  RestaurantFromJSON,
  RestaurantFromJSONTyped,
  RestaurantToJSON,
} from "./Restaurant";

/**
 * Index restaurant response object.
 * @export
 * @interface IndexRestaurantResponse
 */
export interface IndexRestaurantResponse {
  /**
   *
   * @type {Array<Restaurant>}
   * @memberof IndexRestaurantResponse
   */
  data: Array<Restaurant>;
  /**
   *
   * @type {PaginationMeta}
   * @memberof IndexRestaurantResponse
   */
  meta: PaginationMeta;
  /**
   *
   * @type {string}
   * @memberof IndexRestaurantResponse
   */
  message: string;
}

/**
 * Check if a given object implements the IndexRestaurantResponse interface.
 */
export function instanceOfIndexRestaurantResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "meta" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function IndexRestaurantResponseFromJSON(
  json: any
): IndexRestaurantResponse {
  return IndexRestaurantResponseFromJSONTyped(json, false);
}

export function IndexRestaurantResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndexRestaurantResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(RestaurantFromJSON),
    meta: PaginationMetaFromJSON(json["meta"]),
    message: json["message"],
  };
}

export function IndexRestaurantResponseToJSON(
  value?: IndexRestaurantResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(RestaurantToJSON),
    meta: PaginationMetaToJSON(value.meta),
    message: value.message,
  };
}
