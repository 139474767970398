/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  IndexUserResponse,
  MeResponse,
  ShowUserResponse,
  UnauthenticatedResponse,
  UpdateUserRequest,
  UpdateUserResponse,
} from "../models";
import {
  IndexUserResponseFromJSON,
  IndexUserResponseToJSON,
  MeResponseFromJSON,
  MeResponseToJSON,
  ShowUserResponseFromJSON,
  ShowUserResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
  UpdateUserRequestFromJSON,
  UpdateUserRequestToJSON,
  UpdateUserResponseFromJSON,
  UpdateUserResponseToJSON,
} from "../models";

export interface IndexUsersRequest {
  pageSize?: number;
  pageNumber?: number;
}

export interface MeRequest {
  include?: string;
}

export interface ShowUserRequest {
  id: number;
}

export interface UpdateUserOperationRequest {
  id: number;
  updateUserRequest: UpdateUserRequest;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * Index users.
   */
  async indexUsersRaw(
    requestParameters: IndexUsersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexUserResponse>> {
    const queryParameters: any = {};

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/users`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexUserResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index users.
   */
  async indexUsers(
    requestParameters: IndexUsersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexUserResponse> {
    const response = await this.indexUsersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get currently logged user.
   */
  async meRaw(
    requestParameters: MeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<MeResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/users/me`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MeResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get currently logged user.
   */
  async me(
    requestParameters: MeRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<MeResponse> {
    const response = await this.meRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Show user by id.
   */
  async showUserRaw(
    requestParameters: ShowUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowUserResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/users/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowUserResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show user by id.
   */
  async showUser(
    requestParameters: ShowUserRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowUserResponse> {
    const response = await this.showUserRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update user.
   */
  async updateUserRaw(
    requestParameters: UpdateUserOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UpdateUserResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateUser."
      );
    }

    if (
      requestParameters.updateUserRequest === null ||
      requestParameters.updateUserRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "updateUserRequest",
        "Required parameter requestParameters.updateUserRequest was null or undefined when calling updateUser."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/user/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateUserRequestToJSON(requestParameters.updateUserRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateUserResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update user.
   */
  async updateUser(
    requestParameters: UpdateUserOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UpdateUserResponse> {
    const response = await this.updateUserRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
