/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Media } from "./Media";
import { MediaFromJSON, MediaFromJSONTyped, MediaToJSON } from "./Media";
import type { Schedule } from "./Schedule";
import {
  ScheduleFromJSON,
  ScheduleFromJSONTyped,
  ScheduleToJSON,
} from "./Schedule";

/**
 * Restaurant resource object
 * @export
 * @interface Restaurant
 */
export interface Restaurant {
  /**
   *
   * @type {number}
   * @memberof Restaurant
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  country: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  place: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  fullAddress?: string;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  phone: string | null;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  email: string | null;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  location: string | null;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  website: string | null;
  /**
   *
   * @type {string}
   * @memberof Restaurant
   */
  timezone: string;
  /**
   * Selected timezone offset in minutes.
   * @type {number}
   * @memberof Restaurant
   */
  timezoneOffset: number;
  /**
   *
   * @type {number}
   * @memberof Restaurant
   */
  popularity: number | null;
  /**
   *
   * @type {Array<Media>}
   * @memberof Restaurant
   */
  media: Array<Media>;
  /**
   *
   * @type {Array<Schedule>}
   * @memberof Restaurant
   */
  schedules?: Array<Schedule>;
}

/**
 * Check if a given object implements the Restaurant interface.
 */
export function instanceOfRestaurant(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "slug" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "country" in value;
  isInstance = isInstance && "city" in value;
  isInstance = isInstance && "place" in value;
  isInstance = isInstance && "phone" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "location" in value;
  isInstance = isInstance && "website" in value;
  isInstance = isInstance && "timezone" in value;
  isInstance = isInstance && "timezoneOffset" in value;
  isInstance = isInstance && "popularity" in value;
  isInstance = isInstance && "media" in value;

  return isInstance;
}

export function RestaurantFromJSON(json: any): Restaurant {
  return RestaurantFromJSONTyped(json, false);
}

export function RestaurantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Restaurant {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    slug: json["slug"],
    name: json["name"],
    country: json["country"],
    city: json["city"],
    place: json["place"],
    fullAddress: !exists(json, "full_address")
      ? undefined
      : json["full_address"],
    phone: json["phone"],
    email: json["email"],
    location: json["location"],
    website: json["website"],
    timezone: json["timezone"],
    timezoneOffset: json["timezone_offset"],
    popularity: json["popularity"],
    media: (json["media"] as Array<any>).map(MediaFromJSON),
    schedules: !exists(json, "schedules")
      ? undefined
      : (json["schedules"] as Array<any>).map(ScheduleFromJSON),
  };
}

export function RestaurantToJSON(value?: Restaurant | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    slug: value.slug,
    name: value.name,
    country: value.country,
    city: value.city,
    place: value.place,
    full_address: value.fullAddress,
    phone: value.phone,
    email: value.email,
    location: value.location,
    website: value.website,
    timezone: value.timezone,
    timezone_offset: value.timezoneOffset,
    popularity: value.popularity,
    media: (value.media as Array<any>).map(MediaToJSON),
    schedules:
      value.schedules === undefined
        ? undefined
        : (value.schedules as Array<any>).map(ScheduleToJSON),
  };
}
