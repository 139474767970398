/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { PaginationMeta } from "./PaginationMeta";
import {
  PaginationMetaFromJSON,
  PaginationMetaFromJSONTyped,
  PaginationMetaToJSON,
} from "./PaginationMeta";
import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 * Index users response object.
 * @export
 * @interface IndexUserResponse
 */
export interface IndexUserResponse {
  /**
   *
   * @type {Array<User>}
   * @memberof IndexUserResponse
   */
  data: Array<User>;
  /**
   *
   * @type {PaginationMeta}
   * @memberof IndexUserResponse
   */
  meta: PaginationMeta;
  /**
   *
   * @type {string}
   * @memberof IndexUserResponse
   */
  message: string;
}

/**
 * Check if a given object implements the IndexUserResponse interface.
 */
export function instanceOfIndexUserResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "meta" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function IndexUserResponseFromJSON(json: any): IndexUserResponse {
  return IndexUserResponseFromJSONTyped(json, false);
}

export function IndexUserResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndexUserResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(UserFromJSON),
    meta: PaginationMetaFromJSON(json["meta"]),
    message: json["message"],
  };
}

export function IndexUserResponseToJSON(value?: IndexUserResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(UserToJSON),
    meta: PaginationMetaToJSON(value.meta),
    message: value.message,
  };
}
