/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Comment } from "./Comment";
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
} from "./Comment";
import type { FamilyMember } from "./FamilyMember";
import {
  FamilyMemberFromJSON,
  FamilyMemberFromJSONTyped,
  FamilyMemberToJSON,
} from "./FamilyMember";
import type { Restaurant } from "./Restaurant";
import {
  RestaurantFromJSON,
  RestaurantFromJSONTyped,
  RestaurantToJSON,
} from "./Restaurant";

/**
 * Customer resource object
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  restaurantId: number | null;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  surname: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  email: string | null;
  /**
   *
   * @type {Date}
   * @memberof Customer
   */
  birthdate: Date | null;
  /**
   *
   * @type {Restaurant}
   * @memberof Customer
   */
  restaurant?: Restaurant;
  /**
   *
   * @type {Array<FamilyMember>}
   * @memberof Customer
   */
  familyMembers?: Array<FamilyMember>;
  /**
   *
   * @type {Array<Comment>}
   * @memberof Customer
   */
  comments?: Array<Comment>;
}

/**
 * Check if a given object implements the Customer interface.
 */
export function instanceOfCustomer(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "restaurantId" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "surname" in value;
  isInstance = isInstance && "phone" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "birthdate" in value;

  return isInstance;
}

export function CustomerFromJSON(json: any): Customer {
  return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Customer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    restaurantId: json["restaurant_id"],
    type: json["type"],
    name: json["name"],
    surname: json["surname"],
    phone: json["phone"],
    email: json["email"],
    birthdate: json["birthdate"] === null ? null : new Date(json["birthdate"]),
    restaurant: !exists(json, "restaurant")
      ? undefined
      : RestaurantFromJSON(json["restaurant"]),
    familyMembers: !exists(json, "family_members")
      ? undefined
      : (json["family_members"] as Array<any>).map(FamilyMemberFromJSON),
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(CommentFromJSON),
  };
}

export function CustomerToJSON(value?: Customer | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    restaurant_id: value.restaurantId,
    type: value.type,
    name: value.name,
    surname: value.surname,
    phone: value.phone,
    email: value.email,
    birthdate:
      value.birthdate === null
        ? null
        : value.birthdate.toISOString().substr(0, 10),
    restaurant: RestaurantToJSON(value.restaurant),
    family_members:
      value.familyMembers === undefined
        ? undefined
        : (value.familyMembers as Array<any>).map(FamilyMemberToJSON),
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(CommentToJSON),
  };
}
