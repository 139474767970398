/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Category } from "./Category";
import {
  CategoryFromJSON,
  CategoryFromJSONTyped,
  CategoryToJSON,
} from "./Category";
import type { Media } from "./Media";
import { MediaFromJSON, MediaFromJSONTyped, MediaToJSON } from "./Media";

/**
 * Space resource object
 * @export
 * @interface Space
 */
export interface Space {
  /**
   *
   * @type {number}
   * @memberof Space
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Space
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Space
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Space
   */
  description: string;
  /**
   *
   * @type {number}
   * @memberof Space
   */
  floor: number;
  /**
   *
   * @type {number}
   * @memberof Space
   */
  number: number;
  /**
   *
   * @type {number}
   * @memberof Space
   */
  price: number;
  /**
   *
   * @type {boolean}
   * @memberof Space
   */
  archived: boolean;
  /**
   *
   * @type {number}
   * @memberof Space
   */
  popularity: number | null;
  /**
   *
   * @type {Array<Category>}
   * @memberof Space
   */
  categories?: Array<Category>;
  /**
   *
   * @type {Array<number>}
   * @memberof Space
   */
  categoryIds: Array<number>;
  /**
   *
   * @type {Array<Media>}
   * @memberof Space
   */
  media: Array<Media>;
}

/**
 * Check if a given object implements the Space interface.
 */
export function instanceOfSpace(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "floor" in value;
  isInstance = isInstance && "number" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "archived" in value;
  isInstance = isInstance && "popularity" in value;
  isInstance = isInstance && "categoryIds" in value;
  isInstance = isInstance && "media" in value;

  return isInstance;
}

export function SpaceFromJSON(json: any): Space {
  return SpaceFromJSONTyped(json, false);
}

export function SpaceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Space {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    title: json["title"],
    description: json["description"],
    floor: json["floor"],
    number: json["number"],
    price: json["price"],
    archived: json["archived"],
    popularity: json["popularity"],
    categories: !exists(json, "categories")
      ? undefined
      : (json["categories"] as Array<any>).map(CategoryFromJSON),
    categoryIds: json["category_ids"],
    media: (json["media"] as Array<any>).map(MediaFromJSON),
  };
}

export function SpaceToJSON(value?: Space | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    title: value.title,
    description: value.description,
    floor: value.floor,
    number: value.number,
    price: value.price,
    archived: value.archived,
    popularity: value.popularity,
    categories:
      value.categories === undefined
        ? undefined
        : (value.categories as Array<any>).map(CategoryToJSON),
    category_ids: value.categoryIds,
    media: (value.media as Array<any>).map(MediaToJSON),
  };
}
