/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Store restaurant review request
 * @export
 * @interface StoreRestaurantReviewRequest
 */
export interface StoreRestaurantReviewRequest {
  /**
   *
   * @type {number}
   * @memberof StoreRestaurantReviewRequest
   */
  restaurantId: number;
  /**
   *
   * @type {string}
   * @memberof StoreRestaurantReviewRequest
   */
  ip: string;
  /**
   *
   * @type {string}
   * @memberof StoreRestaurantReviewRequest
   */
  reviewer: string;
  /**
   * Min value: `0`, max value: `5`.
   * @type {number}
   * @memberof StoreRestaurantReviewRequest
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof StoreRestaurantReviewRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof StoreRestaurantReviewRequest
   */
  description?: string;
}

/**
 * Check if a given object implements the StoreRestaurantReviewRequest interface.
 */
export function instanceOfStoreRestaurantReviewRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "restaurantId" in value;
  isInstance = isInstance && "ip" in value;
  isInstance = isInstance && "reviewer" in value;
  isInstance = isInstance && "score" in value;

  return isInstance;
}

export function StoreRestaurantReviewRequestFromJSON(
  json: any
): StoreRestaurantReviewRequest {
  return StoreRestaurantReviewRequestFromJSONTyped(json, false);
}

export function StoreRestaurantReviewRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreRestaurantReviewRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    restaurantId: json["restaurant_id"],
    ip: json["ip"],
    reviewer: json["reviewer"],
    score: json["score"],
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
  };
}

export function StoreRestaurantReviewRequestToJSON(
  value?: StoreRestaurantReviewRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    restaurant_id: value.restaurantId,
    ip: value.ip,
    reviewer: value.reviewer,
    score: value.score,
    title: value.title,
    description: value.description,
  };
}
