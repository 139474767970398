/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Restaurant review resource object
 * @export
 * @interface RestaurantReview
 */
export interface RestaurantReview {
  /**
   *
   * @type {number}
   * @memberof RestaurantReview
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof RestaurantReview
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof RestaurantReview
   */
  restaurantId: number;
  /**
   *
   * @type {string}
   * @memberof RestaurantReview
   */
  ip: string;
  /**
   *
   * @type {string}
   * @memberof RestaurantReview
   */
  reviewer: string;
  /**
   * Min value: `0`, max value: `5`.
   * @type {number}
   * @memberof RestaurantReview
   */
  score: number;
  /**
   *
   * @type {string}
   * @memberof RestaurantReview
   */
  title: string | null;
  /**
   *
   * @type {string}
   * @memberof RestaurantReview
   */
  description: string | null;
  /**
   *
   * @type {Date}
   * @memberof RestaurantReview
   */
  createdAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof RestaurantReview
   */
  updatedAt: Date | null;
}

/**
 * Check if a given object implements the RestaurantReview interface.
 */
export function instanceOfRestaurantReview(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "restaurantId" in value;
  isInstance = isInstance && "ip" in value;
  isInstance = isInstance && "reviewer" in value;
  isInstance = isInstance && "score" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "createdAt" in value;
  isInstance = isInstance && "updatedAt" in value;

  return isInstance;
}

export function RestaurantReviewFromJSON(json: any): RestaurantReview {
  return RestaurantReviewFromJSONTyped(json, false);
}

export function RestaurantReviewFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RestaurantReview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    restaurantId: json["restaurant_id"],
    ip: json["ip"],
    reviewer: json["reviewer"],
    score: json["score"],
    title: json["title"],
    description: json["description"],
    createdAt:
      json["created_at"] === null ? null : new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
  };
}

export function RestaurantReviewToJSON(value?: RestaurantReview | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    restaurant_id: value.restaurantId,
    ip: value.ip,
    reviewer: value.reviewer,
    score: value.score,
    title: value.title,
    description: value.description,
    created_at: value.createdAt === null ? null : value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
  };
}
