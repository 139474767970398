/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ArchivedParameter,
  IndexSpaceReservationsResponse,
  IndexSpaceResponse,
  ShowSpaceResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  ArchivedParameterFromJSON,
  ArchivedParameterToJSON,
  IndexSpaceReservationsResponseFromJSON,
  IndexSpaceReservationsResponseToJSON,
  IndexSpaceResponseFromJSON,
  IndexSpaceResponseToJSON,
  ShowSpaceResponseFromJSON,
  ShowSpaceResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface IndexSpacesRequest {
  include?: string;
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  filterIds?: string;
  filterTitle?: string;
  filterCategories?: string;
  filterRestaurants?: string;
  archived?: ArchivedParameter;
}

export interface IndexSpacesReservationsRequest {
  startAt: Date;
  orderId?: number;
  endAt?: Date;
}

export interface ShowSpaceRequest {
  id: number;
  include?: string;
}

/**
 *
 */
export class SpacesApi extends runtime.BaseAPI {
  /**
   * Index spaces.
   */
  async indexSpacesRaw(
    requestParameters: IndexSpacesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexSpaceResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterIds !== undefined) {
      queryParameters["filter[ids]"] = requestParameters.filterIds;
    }

    if (requestParameters.filterTitle !== undefined) {
      queryParameters["filter[title]"] = requestParameters.filterTitle;
    }

    if (requestParameters.filterCategories !== undefined) {
      queryParameters["filter[categories]"] =
        requestParameters.filterCategories;
    }

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/spaces`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexSpaceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index spaces.
   */
  async indexSpaces(
    requestParameters: IndexSpacesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexSpaceResponse> {
    const response = await this.indexSpacesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Index spaces reservations.
   */
  async indexSpacesReservationsRaw(
    requestParameters: IndexSpacesReservationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexSpaceReservationsResponse>> {
    if (
      requestParameters.startAt === null ||
      requestParameters.startAt === undefined
    ) {
      throw new runtime.RequiredError(
        "startAt",
        "Required parameter requestParameters.startAt was null or undefined when calling indexSpacesReservations."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.orderId !== undefined) {
      queryParameters["order_id"] = requestParameters.orderId;
    }

    if (requestParameters.startAt !== undefined) {
      queryParameters["start_at"] = (
        requestParameters.startAt as any
      ).toISOString();
    }

    if (requestParameters.endAt !== undefined) {
      queryParameters["end_at"] = (
        requestParameters.endAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/spaces/reservations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexSpaceReservationsResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index spaces reservations.
   */
  async indexSpacesReservations(
    requestParameters: IndexSpacesReservationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexSpaceReservationsResponse> {
    const response = await this.indexSpacesReservationsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show space by id.
   */
  async showSpaceRaw(
    requestParameters: ShowSpaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowSpaceResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showSpace."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/spaces/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowSpaceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show space by id.
   */
  async showSpace(
    requestParameters: ShowSpaceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowSpaceResponse> {
    const response = await this.showSpaceRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
