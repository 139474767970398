/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Comment } from "./Comment";
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
} from "./Comment";
import type { Discount } from "./Discount";
import {
  DiscountFromJSON,
  DiscountFromJSONTyped,
  DiscountToJSON,
} from "./Discount";

/**
 * Product order field resource object
 * @export
 * @interface ProductOrderField
 */
export interface ProductOrderField {
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ProductOrderField
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  productId: number;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  variantId: number;
  /**
   *
   * @type {string}
   * @memberof ProductOrderField
   */
  batch: string | null;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  amount: number;
  /**
   * 24-hours format time, HOURS:MINUTES
   * @type {string}
   * @memberof ProductOrderField
   */
  serveAt: string | null;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  discountsAmount: number;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  discountsPercent: number;
  /**
   *
   * @type {number}
   * @memberof ProductOrderField
   */
  discountedTotal: number;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ProductOrderField
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {Array<Discount>}
   * @memberof ProductOrderField
   */
  discounts?: Array<Discount>;
}

/**
 * Check if a given object implements the ProductOrderField interface.
 */
export function instanceOfProductOrderField(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "orderId" in value;
  isInstance = isInstance && "productId" in value;
  isInstance = isInstance && "variantId" in value;
  isInstance = isInstance && "batch" in value;
  isInstance = isInstance && "amount" in value;
  isInstance = isInstance && "serveAt" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "discountsAmount" in value;
  isInstance = isInstance && "discountsPercent" in value;
  isInstance = isInstance && "discountedTotal" in value;

  return isInstance;
}

export function ProductOrderFieldFromJSON(json: any): ProductOrderField {
  return ProductOrderFieldFromJSONTyped(json, false);
}

export function ProductOrderFieldFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOrderField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    orderId: json["order_id"],
    productId: json["product_id"],
    variantId: json["variant_id"],
    batch: json["batch"],
    amount: json["amount"],
    serveAt: json["serve_at"],
    total: json["total"],
    discountsAmount: json["discounts_amount"],
    discountsPercent: json["discounts_percent"],
    discountedTotal: json["discounted_total"],
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(CommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(DiscountFromJSON),
  };
}

export function ProductOrderFieldToJSON(value?: ProductOrderField | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    order_id: value.orderId,
    product_id: value.productId,
    variant_id: value.variantId,
    batch: value.batch,
    amount: value.amount,
    serve_at: value.serveAt,
    total: value.total,
    discounts_amount: value.discountsAmount,
    discounts_percent: value.discountsPercent,
    discounted_total: value.discountedTotal,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(CommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(DiscountToJSON),
  };
}
