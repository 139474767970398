/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Update customer request
 * @export
 * @interface UpdateCustomerRequest
 */
export interface UpdateCustomerRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateCustomerRequest
   */
  restaurantId?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  email?: string;
  /**
   * Phone number may start with a plus and must contain only digits 0-9.
   * @type {string}
   * @memberof UpdateCustomerRequest
   */
  phone?: string;
  /**
   *
   * @type {Date}
   * @memberof UpdateCustomerRequest
   */
  birthdate?: Date;
}

/**
 * Check if a given object implements the UpdateCustomerRequest interface.
 */
export function instanceOfUpdateCustomerRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateCustomerRequestFromJSON(
  json: any
): UpdateCustomerRequest {
  return UpdateCustomerRequestFromJSONTyped(json, false);
}

export function UpdateCustomerRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateCustomerRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    restaurantId: !exists(json, "restaurant_id")
      ? undefined
      : json["restaurant_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    surname: !exists(json, "surname") ? undefined : json["surname"],
    email: !exists(json, "email") ? undefined : json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    birthdate: !exists(json, "birthdate")
      ? undefined
      : new Date(json["birthdate"]),
  };
}

export function UpdateCustomerRequestToJSON(
  value?: UpdateCustomerRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    restaurant_id: value.restaurantId,
    name: value.name,
    surname: value.surname,
    email: value.email,
    phone: value.phone,
    birthdate:
      value.birthdate === undefined
        ? undefined
        : value.birthdate.toISOString().substr(0, 10),
  };
}
