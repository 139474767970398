/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Order totals resource object
 * @export
 * @interface OrderTotals
 */
export interface OrderTotals {
  /**
   *
   * @type {number}
   * @memberof OrderTotals
   */
  all: number;
  /**
   *
   * @type {number}
   * @memberof OrderTotals
   */
  spaces: number;
  /**
   *
   * @type {number}
   * @memberof OrderTotals
   */
  tickets: number;
  /**
   *
   * @type {number}
   * @memberof OrderTotals
   */
  services: number;
  /**
   *
   * @type {number}
   * @memberof OrderTotals
   */
  products: number;
}

/**
 * Check if a given object implements the OrderTotals interface.
 */
export function instanceOfOrderTotals(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "all" in value;
  isInstance = isInstance && "spaces" in value;
  isInstance = isInstance && "tickets" in value;
  isInstance = isInstance && "services" in value;
  isInstance = isInstance && "products" in value;

  return isInstance;
}

export function OrderTotalsFromJSON(json: any): OrderTotals {
  return OrderTotalsFromJSONTyped(json, false);
}

export function OrderTotalsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderTotals {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    all: json["all"],
    spaces: json["spaces"],
    tickets: json["tickets"],
    services: json["services"],
    products: json["products"],
  };
}

export function OrderTotalsToJSON(value?: OrderTotals | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    all: value.all,
    spaces: value.spaces,
    tickets: value.tickets,
    services: value.services,
    products: value.products,
  };
}
