/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Register user request
 * @export
 * @interface RegisterRequest
 */
export interface RegisterRequest {
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  phone?: string | null;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof RegisterRequest
   */
  passwordConfirmation: string;
}

/**
 * Check if a given object implements the RegisterRequest interface.
 */
export function instanceOfRegisterRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "password" in value;
  isInstance = isInstance && "passwordConfirmation" in value;

  return isInstance;
}

export function RegisterRequestFromJSON(json: any): RegisterRequest {
  return RegisterRequestFromJSONTyped(json, false);
}

export function RegisterRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegisterRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    surname: !exists(json, "surname") ? undefined : json["surname"],
    email: json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    password: json["password"],
    passwordConfirmation: json["password_confirmation"],
  };
}

export function RegisterRequestToJSON(value?: RegisterRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    surname: value.surname,
    email: value.email,
    phone: value.phone,
    password: value.password,
    password_confirmation: value.passwordConfirmation,
  };
}
