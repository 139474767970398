/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Waiter } from "./Waiter";
import { WaiterFromJSON, WaiterFromJSONTyped, WaiterToJSON } from "./Waiter";

/**
 * Store waiter response object.
 * @export
 * @interface StoreWaiterResponse
 */
export interface StoreWaiterResponse {
  /**
   *
   * @type {Waiter}
   * @memberof StoreWaiterResponse
   */
  data: Waiter;
  /**
   *
   * @type {string}
   * @memberof StoreWaiterResponse
   */
  message: string;
}

/**
 * Check if a given object implements the StoreWaiterResponse interface.
 */
export function instanceOfStoreWaiterResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function StoreWaiterResponseFromJSON(json: any): StoreWaiterResponse {
  return StoreWaiterResponseFromJSONTyped(json, false);
}

export function StoreWaiterResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreWaiterResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: WaiterFromJSON(json["data"]),
    message: json["message"],
  };
}

export function StoreWaiterResponseToJSON(
  value?: StoreWaiterResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: WaiterToJSON(value.data),
    message: value.message,
  };
}
