/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ArchivedParameter,
  IndexTicketResponse,
  ShowTicketResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  ArchivedParameterFromJSON,
  ArchivedParameterToJSON,
  IndexTicketResponseFromJSON,
  IndexTicketResponseToJSON,
  ShowTicketResponseFromJSON,
  ShowTicketResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface IndexTicketsRequest {
  include?: string;
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  filterTitle?: string;
  filterCategories?: string;
  filterRestaurants?: string;
  archived?: ArchivedParameter;
}

export interface ShowTicketRequest {
  id: number;
  include?: string;
}

/**
 *
 */
export class TicketsApi extends runtime.BaseAPI {
  /**
   * Index tickets.
   */
  async indexTicketsRaw(
    requestParameters: IndexTicketsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexTicketResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterTitle !== undefined) {
      queryParameters["filter[title]"] = requestParameters.filterTitle;
    }

    if (requestParameters.filterCategories !== undefined) {
      queryParameters["filter[categories]"] =
        requestParameters.filterCategories;
    }

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/tickets`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexTicketResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index tickets.
   */
  async indexTickets(
    requestParameters: IndexTicketsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexTicketResponse> {
    const response = await this.indexTicketsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show ticket by id.
   */
  async showTicketRaw(
    requestParameters: ShowTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowTicketResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showTicket."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/tickets/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowTicketResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show ticket by id.
   */
  async showTicket(
    requestParameters: ShowTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowTicketResponse> {
    const response = await this.showTicketRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
