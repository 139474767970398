/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AttachingComment } from "./AttachingComment";
import {
  AttachingCommentFromJSON,
  AttachingCommentFromJSONTyped,
  AttachingCommentToJSON,
} from "./AttachingComment";
import type { AttachingDiscount } from "./AttachingDiscount";
import {
  AttachingDiscountFromJSON,
  AttachingDiscountFromJSONTyped,
  AttachingDiscountToJSON,
} from "./AttachingDiscount";
import type { StoreOrderRequestProductField } from "./StoreOrderRequestProductField";
import {
  StoreOrderRequestProductFieldFromJSON,
  StoreOrderRequestProductFieldFromJSONTyped,
  StoreOrderRequestProductFieldToJSON,
} from "./StoreOrderRequestProductField";
import type { StoreOrderRequestServiceField } from "./StoreOrderRequestServiceField";
import {
  StoreOrderRequestServiceFieldFromJSON,
  StoreOrderRequestServiceFieldFromJSONTyped,
  StoreOrderRequestServiceFieldToJSON,
} from "./StoreOrderRequestServiceField";
import type { StoreOrderRequestSpaceField } from "./StoreOrderRequestSpaceField";
import {
  StoreOrderRequestSpaceFieldFromJSON,
  StoreOrderRequestSpaceFieldFromJSONTyped,
  StoreOrderRequestSpaceFieldToJSON,
} from "./StoreOrderRequestSpaceField";
import type { StoreOrderRequestTicketField } from "./StoreOrderRequestTicketField";
import {
  StoreOrderRequestTicketFieldFromJSON,
  StoreOrderRequestTicketFieldFromJSONTyped,
  StoreOrderRequestTicketFieldToJSON,
} from "./StoreOrderRequestTicketField";

/**
 * Store order request
 * @export
 * @interface StoreOrderRequest
 */
export interface StoreOrderRequest {
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequest
   */
  banquetId: number;
  /**
   *
   * @type {Array<StoreOrderRequestSpaceField>}
   * @memberof StoreOrderRequest
   */
  spaces?: Array<StoreOrderRequestSpaceField>;
  /**
   *
   * @type {Array<StoreOrderRequestTicketField>}
   * @memberof StoreOrderRequest
   */
  tickets?: Array<StoreOrderRequestTicketField>;
  /**
   *
   * @type {Array<StoreOrderRequestServiceField>}
   * @memberof StoreOrderRequest
   */
  services?: Array<StoreOrderRequestServiceField>;
  /**
   *
   * @type {Array<StoreOrderRequestProductField>}
   * @memberof StoreOrderRequest
   */
  products?: Array<StoreOrderRequestProductField>;
  /**
   *
   * @type {Array<AttachingComment>}
   * @memberof StoreOrderRequest
   */
  comments?: Array<AttachingComment>;
  /**
   *
   * @type {Array<AttachingDiscount>}
   * @memberof StoreOrderRequest
   */
  discounts?: Array<AttachingDiscount>;
}

/**
 * Check if a given object implements the StoreOrderRequest interface.
 */
export function instanceOfStoreOrderRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "banquetId" in value;

  return isInstance;
}

export function StoreOrderRequestFromJSON(json: any): StoreOrderRequest {
  return StoreOrderRequestFromJSONTyped(json, false);
}

export function StoreOrderRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreOrderRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    banquetId: json["banquet_id"],
    spaces: !exists(json, "spaces")
      ? undefined
      : (json["spaces"] as Array<any>).map(StoreOrderRequestSpaceFieldFromJSON),
    tickets: !exists(json, "tickets")
      ? undefined
      : (json["tickets"] as Array<any>).map(
          StoreOrderRequestTicketFieldFromJSON
        ),
    services: !exists(json, "services")
      ? undefined
      : (json["services"] as Array<any>).map(
          StoreOrderRequestServiceFieldFromJSON
        ),
    products: !exists(json, "products")
      ? undefined
      : (json["products"] as Array<any>).map(
          StoreOrderRequestProductFieldFromJSON
        ),
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(AttachingCommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(AttachingDiscountFromJSON),
  };
}

export function StoreOrderRequestToJSON(value?: StoreOrderRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    banquet_id: value.banquetId,
    spaces:
      value.spaces === undefined
        ? undefined
        : (value.spaces as Array<any>).map(StoreOrderRequestSpaceFieldToJSON),
    tickets:
      value.tickets === undefined
        ? undefined
        : (value.tickets as Array<any>).map(StoreOrderRequestTicketFieldToJSON),
    services:
      value.services === undefined
        ? undefined
        : (value.services as Array<any>).map(
            StoreOrderRequestServiceFieldToJSON
          ),
    products:
      value.products === undefined
        ? undefined
        : (value.products as Array<any>).map(
            StoreOrderRequestProductFieldToJSON
          ),
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(AttachingCommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(AttachingDiscountToJSON),
  };
}
