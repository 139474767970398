/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Update user request
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUserRequest
   */
  email?: string;
  /**
   * This is a new password. It must be different than the current one.
   * @type {string}
   * @memberof UpdateUserRequest
   */
  password?: string;
  /**
   * This is a current password. It is needed in order to perform
   *      authorization. Required with `password`.
   * @type {string}
   * @memberof UpdateUserRequest
   */
  currentPassword?: string;
}

/**
 * Check if a given object implements the UpdateUserRequest interface.
 */
export function instanceOfUpdateUserRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateUserRequestFromJSON(json: any): UpdateUserRequest {
  return UpdateUserRequestFromJSONTyped(json, false);
}

export function UpdateUserRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateUserRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, "name") ? undefined : json["name"],
    email: !exists(json, "email") ? undefined : json["email"],
    password: !exists(json, "password") ? undefined : json["password"],
    currentPassword: !exists(json, "current_password")
      ? undefined
      : json["current_password"],
  };
}

export function UpdateUserRequestToJSON(value?: UpdateUserRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    email: value.email,
    password: value.password,
    current_password: value.currentPassword,
  };
}
