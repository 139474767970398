/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Alternation } from "./Alternation";
import {
  AlternationFromJSON,
  AlternationFromJSONTyped,
  AlternationToJSON,
} from "./Alternation";

/**
 * Product variant resource object
 * @export
 * @interface ProductVariant
 */
export interface ProductVariant {
  /**
   *
   * @type {number}
   * @memberof ProductVariant
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ProductVariant
   */
  productId: number;
  /**
   *
   * @type {string}
   * @memberof ProductVariant
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof ProductVariant
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof ProductVariant
   */
  weight: number;
  /**
   *
   * @type {string}
   * @memberof ProductVariant
   */
  weightUnit: ProductVariantWeightUnitEnum;
  /**
   *
   * @type {Array<Alternation>}
   * @memberof ProductVariant
   */
  alterations?: Array<Alternation>;
  /**
   *
   * @type {Array<Alternation>}
   * @memberof ProductVariant
   */
  pendingAlterations?: Array<Alternation>;
  /**
   *
   * @type {Array<Alternation>}
   * @memberof ProductVariant
   */
  performedAlterations?: Array<Alternation>;
}

/**
 * @export
 */
export const ProductVariantWeightUnitEnum = {
  G: "g",
  Kg: "kg",
  Ml: "ml",
  L: "l",
  Cm: "cm",
} as const;
export type ProductVariantWeightUnitEnum =
  (typeof ProductVariantWeightUnitEnum)[keyof typeof ProductVariantWeightUnitEnum];

/**
 * Check if a given object implements the ProductVariant interface.
 */
export function instanceOfProductVariant(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "productId" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "weight" in value;
  isInstance = isInstance && "weightUnit" in value;

  return isInstance;
}

export function ProductVariantFromJSON(json: any): ProductVariant {
  return ProductVariantFromJSONTyped(json, false);
}

export function ProductVariantFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductVariant {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    productId: json["product_id"],
    type: json["type"],
    price: json["price"],
    weight: json["weight"],
    weightUnit: json["weight_unit"],
    alterations: !exists(json, "alterations")
      ? undefined
      : (json["alterations"] as Array<any>).map(AlternationFromJSON),
    pendingAlterations: !exists(json, "pendingAlterations")
      ? undefined
      : (json["pendingAlterations"] as Array<any>).map(AlternationFromJSON),
    performedAlterations: !exists(json, "performedAlterations")
      ? undefined
      : (json["performedAlterations"] as Array<any>).map(AlternationFromJSON),
  };
}

export function ProductVariantToJSON(value?: ProductVariant | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    product_id: value.productId,
    type: value.type,
    price: value.price,
    weight: value.weight,
    weight_unit: value.weightUnit,
    alterations:
      value.alterations === undefined
        ? undefined
        : (value.alterations as Array<any>).map(AlternationToJSON),
    pendingAlterations:
      value.pendingAlterations === undefined
        ? undefined
        : (value.pendingAlterations as Array<any>).map(AlternationToJSON),
    performedAlterations:
      value.performedAlterations === undefined
        ? undefined
        : (value.performedAlterations as Array<any>).map(AlternationToJSON),
  };
}
