/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DeletedParameter,
  DestroyBanquetResponse,
  DestroyRequest,
  IndexBanquetResponse,
  RestoreBanquetResponse,
  ShowBanquetResponse,
  ShowOrderResponse,
  StoreBanquetRequest,
  StoreBanquetResponse,
  UnauthenticatedResponse,
  UpdateBanquetRequest,
  UpdateBanquetResponse,
} from "../models";
import {
  DeletedParameterFromJSON,
  DeletedParameterToJSON,
  DestroyBanquetResponseFromJSON,
  DestroyBanquetResponseToJSON,
  DestroyRequestFromJSON,
  DestroyRequestToJSON,
  IndexBanquetResponseFromJSON,
  IndexBanquetResponseToJSON,
  RestoreBanquetResponseFromJSON,
  RestoreBanquetResponseToJSON,
  ShowBanquetResponseFromJSON,
  ShowBanquetResponseToJSON,
  ShowOrderResponseFromJSON,
  ShowOrderResponseToJSON,
  StoreBanquetRequestFromJSON,
  StoreBanquetRequestToJSON,
  StoreBanquetResponseFromJSON,
  StoreBanquetResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
  UpdateBanquetRequestFromJSON,
  UpdateBanquetRequestToJSON,
  UpdateBanquetResponseFromJSON,
  UpdateBanquetResponseToJSON,
} from "../models";

export interface DestroyBanquetRequest {
  id: number;
  destroyRequest?: DestroyRequest;
}

export interface IndexBanquetsRequest {
  include?: string;
  filterSearch?: string;
  filterRestaurantId?: number;
  filterState?: string;
  filterFrom?: Date;
  filterUntil?: Date;
  sort?: string;
  pageSize?: number;
  pageNumber?: number;
  deleted?: DeletedParameter;
}

export interface RestoreBanquetRequest {
  id: number;
}

export interface ShowBanquetRequest {
  id: number;
  include?: string;
}

export interface ShowOrderByBanquetIdRequest {
  id: number;
  include?: string;
}

export interface StoreBanquetOperationRequest {
  storeBanquetRequest: StoreBanquetRequest;
  include?: string;
}

export interface UpdateBanquetOperationRequest {
  id: number;
  updateBanquetRequest: UpdateBanquetRequest;
  include?: string;
}

/**
 *
 */
export class BanquetsApi extends runtime.BaseAPI {
  /**
   * Delete banquet.
   */
  async destroyBanquetRaw(
    requestParameters: DestroyBanquetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DestroyBanquetResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling destroyBanquet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
        body: DestroyRequestToJSON(requestParameters.destroyRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DestroyBanquetResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete banquet.
   */
  async destroyBanquet(
    requestParameters: DestroyBanquetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DestroyBanquetResponse> {
    const response = await this.destroyBanquetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Index banquets.
   */
  async indexBanquetsRaw(
    requestParameters: IndexBanquetsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexBanquetResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.filterSearch !== undefined) {
      queryParameters["filter[search]"] = requestParameters.filterSearch;
    }

    if (requestParameters.filterRestaurantId !== undefined) {
      queryParameters["filter[restaurant_id]"] =
        requestParameters.filterRestaurantId;
    }

    if (requestParameters.filterState !== undefined) {
      queryParameters["filter[state]"] = requestParameters.filterState;
    }

    if (requestParameters.filterFrom !== undefined) {
      queryParameters["filter[from]"] = (requestParameters.filterFrom as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.filterUntil !== undefined) {
      queryParameters["filter[until]"] = (requestParameters.filterUntil as any)
        .toISOString()
        .substr(0, 10);
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.deleted !== undefined) {
      queryParameters["deleted"] = requestParameters.deleted;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexBanquetResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index banquets.
   */
  async indexBanquets(
    requestParameters: IndexBanquetsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexBanquetResponse> {
    const response = await this.indexBanquetsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Restore banquet.
   */
  async restoreBanquetRaw(
    requestParameters: RestoreBanquetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RestoreBanquetResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling restoreBanquet."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets/{id}/restore`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RestoreBanquetResponseFromJSON(jsonValue)
    );
  }

  /**
   * Restore banquet.
   */
  async restoreBanquet(
    requestParameters: RestoreBanquetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RestoreBanquetResponse> {
    const response = await this.restoreBanquetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show banquets by id.
   */
  async showBanquetRaw(
    requestParameters: ShowBanquetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowBanquetResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showBanquet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowBanquetResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show banquets by id.
   */
  async showBanquet(
    requestParameters: ShowBanquetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowBanquetResponse> {
    const response = await this.showBanquetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show order by banquet id.
   */
  async showOrderByBanquetIdRaw(
    requestParameters: ShowOrderByBanquetIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowOrderResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showOrderByBanquetId."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets/{id}/order`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show order by banquet id.
   */
  async showOrderByBanquetId(
    requestParameters: ShowOrderByBanquetIdRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowOrderResponse> {
    const response = await this.showOrderByBanquetIdRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Store banquet. Order record will automatically be created.
   */
  async storeBanquetRaw(
    requestParameters: StoreBanquetOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StoreBanquetResponse>> {
    if (
      requestParameters.storeBanquetRequest === null ||
      requestParameters.storeBanquetRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "storeBanquetRequest",
        "Required parameter requestParameters.storeBanquetRequest was null or undefined when calling storeBanquet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StoreBanquetRequestToJSON(requestParameters.storeBanquetRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StoreBanquetResponseFromJSON(jsonValue)
    );
  }

  /**
   * Store banquet. Order record will automatically be created.
   */
  async storeBanquet(
    requestParameters: StoreBanquetOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StoreBanquetResponse> {
    const response = await this.storeBanquetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update banquet.
   */
  async updateBanquetRaw(
    requestParameters: UpdateBanquetOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UpdateBanquetResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateBanquet."
      );
    }

    if (
      requestParameters.updateBanquetRequest === null ||
      requestParameters.updateBanquetRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "updateBanquetRequest",
        "Required parameter requestParameters.updateBanquetRequest was null or undefined when calling updateBanquet."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/banquets/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateBanquetRequestToJSON(
          requestParameters.updateBanquetRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateBanquetResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update banquet.
   */
  async updateBanquet(
    requestParameters: UpdateBanquetOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UpdateBanquetResponse> {
    const response = await this.updateBanquetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
