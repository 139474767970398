/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Order } from "./Order";
import { OrderFromJSON, OrderFromJSONTyped, OrderToJSON } from "./Order";

/**
 * Show order response object.
 * @export
 * @interface ShowOrderResponse
 */
export interface ShowOrderResponse {
  /**
   *
   * @type {Order}
   * @memberof ShowOrderResponse
   */
  data: Order;
  /**
   *
   * @type {string}
   * @memberof ShowOrderResponse
   */
  message: string;
}

/**
 * Check if a given object implements the ShowOrderResponse interface.
 */
export function instanceOfShowOrderResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function ShowOrderResponseFromJSON(json: any): ShowOrderResponse {
  return ShowOrderResponseFromJSONTyped(json, false);
}

export function ShowOrderResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShowOrderResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: OrderFromJSON(json["data"]),
    message: json["message"],
  };
}

export function ShowOrderResponseToJSON(value?: ShowOrderResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: OrderToJSON(value.data),
    message: value.message,
  };
}
