/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Customer } from "./Customer";
import {
  CustomerFromJSON,
  CustomerFromJSONTyped,
  CustomerToJSON,
} from "./Customer";

/**
 * User resource object
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  restaurantId: number | null;
  /**
   *
   * @type {number}
   * @memberof User
   */
  customerId: number | null;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string | null;
  /**
   *
   * @type {Date}
   * @memberof User
   */
  emailVerifiedAt: Date | null;
  /**
   *
   * @type {Customer}
   * @memberof User
   */
  customer?: Customer;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "restaurantId" in value;
  isInstance = isInstance && "customerId" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "emailVerifiedAt" in value;

  return isInstance;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    restaurantId: json["restaurant_id"],
    customerId: json["customer_id"],
    name: json["name"],
    email: json["email"],
    emailVerifiedAt:
      json["email_verified_at"] === null
        ? null
        : new Date(json["email_verified_at"]),
    customer: !exists(json, "customer")
      ? undefined
      : CustomerFromJSON(json["customer"]),
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    restaurant_id: value.restaurantId,
    customer_id: value.customerId,
    name: value.name,
    email: value.email,
    email_verified_at:
      value.emailVerifiedAt === null
        ? null
        : value.emailVerifiedAt.toISOString(),
    customer: CustomerToJSON(value.customer),
  };
}
