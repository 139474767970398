/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Attaching comment
 * @export
 * @interface AttachingComment
 */
export interface AttachingComment {
  /**
   *
   * @type {number}
   * @memberof AttachingComment
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof AttachingComment
   */
  commentableId?: number;
  /**
   *
   * @type {string}
   * @memberof AttachingComment
   */
  commentableType?: string;
  /**
   *
   * @type {string}
   * @memberof AttachingComment
   */
  text: string;
}

/**
 * Check if a given object implements the AttachingComment interface.
 */
export function instanceOfAttachingComment(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "text" in value;

  return isInstance;
}

export function AttachingCommentFromJSON(json: any): AttachingComment {
  return AttachingCommentFromJSONTyped(json, false);
}

export function AttachingCommentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AttachingComment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    commentableId: !exists(json, "commentable_id")
      ? undefined
      : json["commentable_id"],
    commentableType: !exists(json, "commentable_type")
      ? undefined
      : json["commentable_type"],
    text: json["text"],
  };
}

export function AttachingCommentToJSON(value?: AttachingComment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    commentable_id: value.commentableId,
    commentable_type: value.commentableType,
    text: value.text,
  };
}
