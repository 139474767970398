/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Media } from "./Media";
import { MediaFromJSON, MediaFromJSONTyped, MediaToJSON } from "./Media";
import type { Tag } from "./Tag";
import { TagFromJSON, TagFromJSONTyped, TagToJSON } from "./Tag";

/**
 * Category resource object
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {number}
   * @memberof Category
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  target: string | null;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  title: string;
  /**
   *
   * @type {any}
   * @memberof Category
   */
  description: any | null;
  /**
   *
   * @type {Array<Tag>}
   * @memberof Category
   */
  tags?: Array<Tag>;
  /**
   *
   * @type {Array<Media>}
   * @memberof Category
   */
  media: Array<Media>;
}

/**
 * Check if a given object implements the Category interface.
 */
export function instanceOfCategory(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "slug" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "target" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "media" in value;

  return isInstance;
}

export function CategoryFromJSON(json: any): Category {
  return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Category {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    slug: json["slug"],
    type: json["type"],
    target: json["target"],
    title: json["title"],
    description: json["description"],
    tags: !exists(json, "tags")
      ? undefined
      : (json["tags"] as Array<any>).map(TagFromJSON),
    media: (json["media"] as Array<any>).map(MediaFromJSON),
  };
}

export function CategoryToJSON(value?: Category | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    slug: value.slug,
    type: value.type,
    target: value.target,
    title: value.title,
    description: value.description,
    tags:
      value.tags === undefined
        ? undefined
        : (value.tags as Array<any>).map(TagToJSON),
    media: (value.media as Array<any>).map(MediaToJSON),
  };
}
