/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Store waiter request.
 * @export
 * @interface StoreWaiterRequest
 */
export interface StoreWaiterRequest {
  /**
   *
   * @type {number}
   * @memberof StoreWaiterRequest
   */
  restaurantId: number | null;
  /**
   *
   * @type {string}
   * @memberof StoreWaiterRequest
   */
  uuid: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreWaiterRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof StoreWaiterRequest
   */
  surname: string;
  /**
   *
   * @type {string}
   * @memberof StoreWaiterRequest
   */
  email: string | null;
  /**
   * Phone number may start with a plus and must contain only digits 0-9.
   * @type {string}
   * @memberof StoreWaiterRequest
   */
  phone: string | null;
  /**
   *
   * @type {Date}
   * @memberof StoreWaiterRequest
   */
  birthdate: Date | null;
  /**
   *
   * @type {string}
   * @memberof StoreWaiterRequest
   */
  about: string | null;
}

/**
 * Check if a given object implements the StoreWaiterRequest interface.
 */
export function instanceOfStoreWaiterRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "restaurantId" in value;
  isInstance = isInstance && "uuid" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "surname" in value;
  isInstance = isInstance && "email" in value;
  isInstance = isInstance && "phone" in value;
  isInstance = isInstance && "birthdate" in value;
  isInstance = isInstance && "about" in value;

  return isInstance;
}

export function StoreWaiterRequestFromJSON(json: any): StoreWaiterRequest {
  return StoreWaiterRequestFromJSONTyped(json, false);
}

export function StoreWaiterRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreWaiterRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    restaurantId: json["restaurant_id"],
    uuid: json["uuid"],
    name: json["name"],
    surname: json["surname"],
    email: json["email"],
    phone: json["phone"],
    birthdate: json["birthdate"] === null ? null : new Date(json["birthdate"]),
    about: json["about"],
  };
}

export function StoreWaiterRequestToJSON(
  value?: StoreWaiterRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    restaurant_id: value.restaurantId,
    uuid: value.uuid,
    name: value.name,
    surname: value.surname,
    email: value.email,
    phone: value.phone,
    birthdate:
      value.birthdate === null
        ? null
        : value.birthdate.toISOString().substr(0, 10),
    about: value.about,
  };
}
