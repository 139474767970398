/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DeletedParameter,
  DestroyCustomerResponse,
  DestroyRequest,
  IndexCustomerResponse,
  RestoreCustomerResponse,
  ShowCustomerResponse,
  StoreCustomerRequest,
  StoreCustomerResponse,
  UnauthenticatedResponse,
  UpdateCustomerRequest,
  UpdateCustomerResponse,
} from "../models";
import {
  DeletedParameterFromJSON,
  DeletedParameterToJSON,
  DestroyCustomerResponseFromJSON,
  DestroyCustomerResponseToJSON,
  DestroyRequestFromJSON,
  DestroyRequestToJSON,
  IndexCustomerResponseFromJSON,
  IndexCustomerResponseToJSON,
  RestoreCustomerResponseFromJSON,
  RestoreCustomerResponseToJSON,
  ShowCustomerResponseFromJSON,
  ShowCustomerResponseToJSON,
  StoreCustomerRequestFromJSON,
  StoreCustomerRequestToJSON,
  StoreCustomerResponseFromJSON,
  StoreCustomerResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
  UpdateCustomerRequestFromJSON,
  UpdateCustomerRequestToJSON,
  UpdateCustomerResponseFromJSON,
  UpdateCustomerResponseToJSON,
} from "../models";

export interface DestroyCustomerRequest {
  id: number;
  destroyRequest?: DestroyRequest;
}

export interface IndexCustomersRequest {
  include?: string;
  sort?: string;
  filterSearch?: string;
  filterName?: string;
  filterSurname?: string;
  filterPhone?: string;
  filterEmail?: string;
  pageSize?: number;
  pageNumber?: number;
  deleted?: DeletedParameter;
}

export interface RestoreCustomerRequest {
  id: number;
}

export interface ShowCustomerRequest {
  id: number;
  include?: string;
}

export interface StoreCustomerOperationRequest {
  storeCustomerRequest: StoreCustomerRequest;
}

export interface UpdateCustomerOperationRequest {
  id: number;
  updateCustomerRequest: UpdateCustomerRequest;
}

/**
 *
 */
export class CustomersApi extends runtime.BaseAPI {
  /**
   * Delete customer.
   */
  async destroyCustomerRaw(
    requestParameters: DestroyCustomerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DestroyCustomerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling destroyCustomer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/customers/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
        body: DestroyRequestToJSON(requestParameters.destroyRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DestroyCustomerResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete customer.
   */
  async destroyCustomer(
    requestParameters: DestroyCustomerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DestroyCustomerResponse> {
    const response = await this.destroyCustomerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Index customers.
   */
  async indexCustomersRaw(
    requestParameters: IndexCustomersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexCustomerResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterSearch !== undefined) {
      queryParameters["filter[search]"] = requestParameters.filterSearch;
    }

    if (requestParameters.filterName !== undefined) {
      queryParameters["filter[name]"] = requestParameters.filterName;
    }

    if (requestParameters.filterSurname !== undefined) {
      queryParameters["filter[surname]"] = requestParameters.filterSurname;
    }

    if (requestParameters.filterPhone !== undefined) {
      queryParameters["filter[phone]"] = requestParameters.filterPhone;
    }

    if (requestParameters.filterEmail !== undefined) {
      queryParameters["filter[email]"] = requestParameters.filterEmail;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.deleted !== undefined) {
      queryParameters["deleted"] = requestParameters.deleted;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/customers`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexCustomerResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index customers.
   */
  async indexCustomers(
    requestParameters: IndexCustomersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexCustomerResponse> {
    const response = await this.indexCustomersRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Restore customer.
   */
  async restoreCustomerRaw(
    requestParameters: RestoreCustomerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RestoreCustomerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling restoreCustomer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/customers/{id}/restore`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RestoreCustomerResponseFromJSON(jsonValue)
    );
  }

  /**
   * Restore customer.
   */
  async restoreCustomer(
    requestParameters: RestoreCustomerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RestoreCustomerResponse> {
    const response = await this.restoreCustomerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show customer by id.
   */
  async showCustomerRaw(
    requestParameters: ShowCustomerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowCustomerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showCustomer."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/customers/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowCustomerResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show customer by id.
   */
  async showCustomer(
    requestParameters: ShowCustomerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowCustomerResponse> {
    const response = await this.showCustomerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Store customer.
   */
  async storeCustomerRaw(
    requestParameters: StoreCustomerOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StoreCustomerResponse>> {
    if (
      requestParameters.storeCustomerRequest === null ||
      requestParameters.storeCustomerRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "storeCustomerRequest",
        "Required parameter requestParameters.storeCustomerRequest was null or undefined when calling storeCustomer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/customers`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StoreCustomerRequestToJSON(
          requestParameters.storeCustomerRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StoreCustomerResponseFromJSON(jsonValue)
    );
  }

  /**
   * Store customer.
   */
  async storeCustomer(
    requestParameters: StoreCustomerOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StoreCustomerResponse> {
    const response = await this.storeCustomerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update customer.
   */
  async updateCustomerRaw(
    requestParameters: UpdateCustomerOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UpdateCustomerResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateCustomer."
      );
    }

    if (
      requestParameters.updateCustomerRequest === null ||
      requestParameters.updateCustomerRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "updateCustomerRequest",
        "Required parameter requestParameters.updateCustomerRequest was null or undefined when calling updateCustomer."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/customers/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateCustomerRequestToJSON(
          requestParameters.updateCustomerRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateCustomerResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update customer.
   */
  async updateCustomer(
    requestParameters: UpdateCustomerOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UpdateCustomerResponse> {
    const response = await this.updateCustomerRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
