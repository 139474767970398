import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6bbe2c9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_PreviewLayout = _resolveComponent("PreviewLayout")!
  const _component_AuthenticatedLayout = _resolveComponent("AuthenticatedLayout")!

  return (_ctx.isPreview)
    ? (_openBlock(), _createBlock(_component_PreviewLayout, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view)
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_AuthenticatedLayout, { key: 1 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_view)
          ])
        ]),
        _: 1
      }))
}