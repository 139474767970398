/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DeletedParameter,
  DestroyRequest,
  DestroyWaiterResponse,
  IndexWaiterResponse,
  RestoreWaiterResponse,
  ShowWaiterResponse,
  StoreWaiterRequest,
  StoreWaiterResponse,
  UnauthenticatedResponse,
  UpdateWaiterRequest,
  UpdateWaiterResponse,
} from "../models";
import {
  DeletedParameterFromJSON,
  DeletedParameterToJSON,
  DestroyRequestFromJSON,
  DestroyRequestToJSON,
  DestroyWaiterResponseFromJSON,
  DestroyWaiterResponseToJSON,
  IndexWaiterResponseFromJSON,
  IndexWaiterResponseToJSON,
  RestoreWaiterResponseFromJSON,
  RestoreWaiterResponseToJSON,
  ShowWaiterResponseFromJSON,
  ShowWaiterResponseToJSON,
  StoreWaiterRequestFromJSON,
  StoreWaiterRequestToJSON,
  StoreWaiterResponseFromJSON,
  StoreWaiterResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
  UpdateWaiterRequestFromJSON,
  UpdateWaiterRequestToJSON,
  UpdateWaiterResponseFromJSON,
  UpdateWaiterResponseToJSON,
} from "../models";

export interface DestroyWaiterRequest {
  id: number;
  destroyRequest?: DestroyRequest;
}

export interface IndexWaitersRequest {
  filterRestaurants?: string;
  filterSearch?: string;
  filterUuid?: string;
  filterName?: string;
  filterSurname?: string;
  filterPhone?: string;
  filterEmail?: string;
  pageSize?: number;
  pageNumber?: number;
  deleted?: DeletedParameter;
}

export interface RestoreWaiterRequest {
  id: number;
}

export interface ShowWaiterRequest {
  id: number;
}

export interface StoreWaiterOperationRequest {
  storeWaiterRequest: StoreWaiterRequest;
}

export interface UpdateWaiterOperationRequest {
  id: number;
  updateWaiterRequest: UpdateWaiterRequest;
}

/**
 *
 */
export class WaitersApi extends runtime.BaseAPI {
  /**
   * Delete waiter.
   */
  async destroyWaiterRaw(
    requestParameters: DestroyWaiterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DestroyWaiterResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling destroyWaiter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/waiters/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
        body: DestroyRequestToJSON(requestParameters.destroyRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DestroyWaiterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete waiter.
   */
  async destroyWaiter(
    requestParameters: DestroyWaiterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DestroyWaiterResponse> {
    const response = await this.destroyWaiterRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Index waiters.
   */
  async indexWaitersRaw(
    requestParameters: IndexWaitersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexWaiterResponse>> {
    const queryParameters: any = {};

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.filterSearch !== undefined) {
      queryParameters["filter[search]"] = requestParameters.filterSearch;
    }

    if (requestParameters.filterUuid !== undefined) {
      queryParameters["filter[uuid]"] = requestParameters.filterUuid;
    }

    if (requestParameters.filterName !== undefined) {
      queryParameters["filter[name]"] = requestParameters.filterName;
    }

    if (requestParameters.filterSurname !== undefined) {
      queryParameters["filter[surname]"] = requestParameters.filterSurname;
    }

    if (requestParameters.filterPhone !== undefined) {
      queryParameters["filter[phone]"] = requestParameters.filterPhone;
    }

    if (requestParameters.filterEmail !== undefined) {
      queryParameters["filter[email]"] = requestParameters.filterEmail;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.deleted !== undefined) {
      queryParameters["deleted"] = requestParameters.deleted;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/waiters`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexWaiterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index waiters.
   */
  async indexWaiters(
    requestParameters: IndexWaitersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexWaiterResponse> {
    const response = await this.indexWaitersRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Restore waiter.
   */
  async restoreWaiterRaw(
    requestParameters: RestoreWaiterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RestoreWaiterResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling restoreWaiter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/waiters/{id}/restore`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RestoreWaiterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Restore waiter.
   */
  async restoreWaiter(
    requestParameters: RestoreWaiterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RestoreWaiterResponse> {
    const response = await this.restoreWaiterRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show waiter by id.
   */
  async showWaiterRaw(
    requestParameters: ShowWaiterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowWaiterResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showWaiter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/waiters/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowWaiterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show waiter by id.
   */
  async showWaiter(
    requestParameters: ShowWaiterRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowWaiterResponse> {
    const response = await this.showWaiterRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Store waiter.
   */
  async storeWaiterRaw(
    requestParameters: StoreWaiterOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StoreWaiterResponse>> {
    if (
      requestParameters.storeWaiterRequest === null ||
      requestParameters.storeWaiterRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "storeWaiterRequest",
        "Required parameter requestParameters.storeWaiterRequest was null or undefined when calling storeWaiter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/waiters`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StoreWaiterRequestToJSON(requestParameters.storeWaiterRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StoreWaiterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Store waiter.
   */
  async storeWaiter(
    requestParameters: StoreWaiterOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StoreWaiterResponse> {
    const response = await this.storeWaiterRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update waiter.
   */
  async updateWaiterRaw(
    requestParameters: UpdateWaiterOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UpdateWaiterResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateWaiter."
      );
    }

    if (
      requestParameters.updateWaiterRequest === null ||
      requestParameters.updateWaiterRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "updateWaiterRequest",
        "Required parameter requestParameters.updateWaiterRequest was null or undefined when calling updateWaiter."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/waiters/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateWaiterRequestToJSON(requestParameters.updateWaiterRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateWaiterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update waiter.
   */
  async updateWaiter(
    requestParameters: UpdateWaiterOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UpdateWaiterResponse> {
    const response = await this.updateWaiterRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
