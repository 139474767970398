/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Ticket } from "./Ticket";
import { TicketFromJSON, TicketFromJSONTyped, TicketToJSON } from "./Ticket";

/**
 * Show tickets response object.
 * @export
 * @interface ShowTicketResponse
 */
export interface ShowTicketResponse {
  /**
   *
   * @type {Ticket}
   * @memberof ShowTicketResponse
   */
  data: Ticket;
  /**
   *
   * @type {string}
   * @memberof ShowTicketResponse
   */
  message: string;
}

/**
 * Check if a given object implements the ShowTicketResponse interface.
 */
export function instanceOfShowTicketResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function ShowTicketResponseFromJSON(json: any): ShowTicketResponse {
  return ShowTicketResponseFromJSONTyped(json, false);
}

export function ShowTicketResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShowTicketResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: TicketFromJSON(json["data"]),
    message: json["message"],
  };
}

export function ShowTicketResponseToJSON(
  value?: ShowTicketResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: TicketToJSON(value.data),
    message: value.message,
  };
}
