/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AttachingComment } from "./AttachingComment";
import {
  AttachingCommentFromJSON,
  AttachingCommentFromJSONTyped,
  AttachingCommentToJSON,
} from "./AttachingComment";
import type { AttachingDiscount } from "./AttachingDiscount";
import {
  AttachingDiscountFromJSON,
  AttachingDiscountFromJSONTyped,
  AttachingDiscountToJSON,
} from "./AttachingDiscount";

/**
 * Store order request product field
 * @export
 * @interface StoreOrderRequestProductField
 */
export interface StoreOrderRequestProductField {
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequestProductField
   */
  productId: number;
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequestProductField
   */
  variantId: number;
  /**
   *
   * @type {string}
   * @memberof StoreOrderRequestProductField
   */
  batch?: string | null;
  /**
   *
   * @type {number}
   * @memberof StoreOrderRequestProductField
   */
  amount: number;
  /**
   * 24-hours format time, HOURS:MINUTES
   * @type {string}
   * @memberof StoreOrderRequestProductField
   */
  serveAt?: string | null;
  /**
   *
   * @type {Array<AttachingComment>}
   * @memberof StoreOrderRequestProductField
   */
  comments?: Array<AttachingComment>;
  /**
   *
   * @type {Array<AttachingDiscount>}
   * @memberof StoreOrderRequestProductField
   */
  discounts?: Array<AttachingDiscount>;
}

/**
 * Check if a given object implements the StoreOrderRequestProductField interface.
 */
export function instanceOfStoreOrderRequestProductField(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && "productId" in value;
  isInstance = isInstance && "variantId" in value;
  isInstance = isInstance && "amount" in value;

  return isInstance;
}

export function StoreOrderRequestProductFieldFromJSON(
  json: any
): StoreOrderRequestProductField {
  return StoreOrderRequestProductFieldFromJSONTyped(json, false);
}

export function StoreOrderRequestProductFieldFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreOrderRequestProductField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productId: json["product_id"],
    variantId: json["variant_id"],
    batch: !exists(json, "batch") ? undefined : json["batch"],
    amount: json["amount"],
    serveAt: !exists(json, "serve_at") ? undefined : json["serve_at"],
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(AttachingCommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(AttachingDiscountFromJSON),
  };
}

export function StoreOrderRequestProductFieldToJSON(
  value?: StoreOrderRequestProductField | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_id: value.productId,
    variant_id: value.variantId,
    batch: value.batch,
    amount: value.amount,
    serve_at: value.serveAt,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(AttachingCommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(AttachingDiscountToJSON),
  };
}
