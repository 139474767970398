/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Restaurant } from "./Restaurant";
import {
  RestaurantFromJSON,
  RestaurantFromJSONTyped,
  RestaurantToJSON,
} from "./Restaurant";

/**
 * Show restaurant response object.
 * @export
 * @interface ShowRestaurantResponse
 */
export interface ShowRestaurantResponse {
  /**
   *
   * @type {Restaurant}
   * @memberof ShowRestaurantResponse
   */
  data: Restaurant;
  /**
   *
   * @type {string}
   * @memberof ShowRestaurantResponse
   */
  message: string;
}

/**
 * Check if a given object implements the ShowRestaurantResponse interface.
 */
export function instanceOfShowRestaurantResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function ShowRestaurantResponseFromJSON(
  json: any
): ShowRestaurantResponse {
  return ShowRestaurantResponseFromJSONTyped(json, false);
}

export function ShowRestaurantResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShowRestaurantResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: RestaurantFromJSON(json["data"]),
    message: json["message"],
  };
}

export function ShowRestaurantResponseToJSON(
  value?: ShowRestaurantResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: RestaurantToJSON(value.data),
    message: value.message,
  };
}
