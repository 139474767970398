/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Holiday resource object
 * @export
 * @interface Holiday
 */
export interface Holiday {
  /**
   *
   * @type {number}
   * @memberof Holiday
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Holiday
   */
  description: string | null;
  /**
   *
   * @type {Date}
   * @memberof Holiday
   */
  date: Date;
  /**
   *
   * @type {Date}
   * @memberof Holiday
   */
  closestDate?: Date | null;
}

/**
 * Check if a given object implements the Holiday interface.
 */
export function instanceOfHoliday(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "date" in value;

  return isInstance;
}

export function HolidayFromJSON(json: any): Holiday {
  return HolidayFromJSONTyped(json, false);
}

export function HolidayFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Holiday {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    name: json["name"],
    description: json["description"],
    date: new Date(json["date"]),
    closestDate: !exists(json, "closest_date")
      ? undefined
      : json["closest_date"] === null
      ? null
      : new Date(json["closest_date"]),
  };
}

export function HolidayToJSON(value?: Holiday | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    name: value.name,
    description: value.description,
    date: value.date.toISOString().substr(0, 10),
    closest_date:
      value.closestDate === undefined
        ? undefined
        : value.closestDate === null
        ? null
        : value.closestDate.toISOString(),
  };
}
