/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ArchivedParameter,
  IndexProductResponse,
  ShowProductResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  ArchivedParameterFromJSON,
  ArchivedParameterToJSON,
  IndexProductResponseFromJSON,
  IndexProductResponseToJSON,
  ShowProductResponseFromJSON,
  ShowProductResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface IndexProductsRequest {
  include?: string;
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  filterIds?: string;
  filterTitle?: string;
  filterMenus?: string;
  filterCategories?: string;
  filterTags?: string;
  filterRestaurants?: string;
  archived?: ArchivedParameter;
}

export interface ShowProductRequest {
  id: number;
  include?: string;
}

/**
 *
 */
export class ProductsApi extends runtime.BaseAPI {
  /**
   * Index products.
   */
  async indexProductsRaw(
    requestParameters: IndexProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexProductResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterIds !== undefined) {
      queryParameters["filter[ids]"] = requestParameters.filterIds;
    }

    if (requestParameters.filterTitle !== undefined) {
      queryParameters["filter[title]"] = requestParameters.filterTitle;
    }

    if (requestParameters.filterMenus !== undefined) {
      queryParameters["filter[menus]"] = requestParameters.filterMenus;
    }

    if (requestParameters.filterCategories !== undefined) {
      queryParameters["filter[categories]"] =
        requestParameters.filterCategories;
    }

    if (requestParameters.filterTags !== undefined) {
      queryParameters["filter[tags]"] = requestParameters.filterTags;
    }

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/products`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexProductResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index products.
   */
  async indexProducts(
    requestParameters: IndexProductsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexProductResponse> {
    const response = await this.indexProductsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show product by id.
   */
  async showProductRaw(
    requestParameters: ShowProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowProductResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showProduct."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/products/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowProductResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show product by id.
   */
  async showProduct(
    requestParameters: ShowProductRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowProductResponse> {
    const response = await this.showProductRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
