/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Restaurant } from "./Restaurant";
import {
  RestaurantFromJSON,
  RestaurantFromJSONTyped,
  RestaurantToJSON,
} from "./Restaurant";

/**
 * Waiter resource object
 * @export
 * @interface Waiter
 */
export interface Waiter {
  /**
   *
   * @type {number}
   * @memberof Waiter
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Waiter
   */
  restaurantId: number | null;
  /**
   *
   * @type {string}
   * @memberof Waiter
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Waiter
   */
  uuid: string | null;
  /**
   *
   * @type {string}
   * @memberof Waiter
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Waiter
   */
  surname: string;
  /**
   *
   * @type {string}
   * @memberof Waiter
   */
  about: string | null;
  /**
   *
   * @type {Restaurant}
   * @memberof Waiter
   */
  restaurant?: Restaurant;
}

/**
 * Check if a given object implements the Waiter interface.
 */
export function instanceOfWaiter(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "restaurantId" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "uuid" in value;
  isInstance = isInstance && "name" in value;
  isInstance = isInstance && "surname" in value;
  isInstance = isInstance && "about" in value;

  return isInstance;
}

export function WaiterFromJSON(json: any): Waiter {
  return WaiterFromJSONTyped(json, false);
}

export function WaiterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Waiter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    restaurantId: json["restaurant_id"],
    type: json["type"],
    uuid: json["uuid"],
    name: json["name"],
    surname: json["surname"],
    about: json["about"],
    restaurant: !exists(json, "restaurant")
      ? undefined
      : RestaurantFromJSON(json["restaurant"]),
  };
}

export function WaiterToJSON(value?: Waiter | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    restaurant_id: value.restaurantId,
    type: value.type,
    uuid: value.uuid,
    name: value.name,
    surname: value.surname,
    about: value.about,
    restaurant: RestaurantToJSON(value.restaurant),
  };
}
