/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  IndexRestaurantReviewResponse,
  ShowRestaurantReviewResponse,
  StoreRestaurantReviewRequest,
  StoreRestaurantReviewResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  IndexRestaurantReviewResponseFromJSON,
  IndexRestaurantReviewResponseToJSON,
  ShowRestaurantReviewResponseFromJSON,
  ShowRestaurantReviewResponseToJSON,
  StoreRestaurantReviewRequestFromJSON,
  StoreRestaurantReviewRequestToJSON,
  StoreRestaurantReviewResponseFromJSON,
  StoreRestaurantReviewResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface IndexRestaurantReviewsRequest {
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  filterRestaurantId?: string;
  filterIp?: string;
  isApproved?: boolean;
}

export interface ShowRestaurantReviewRequest {
  id: number;
}

export interface StoreRestaurantReviewOperationRequest {
  storeRestaurantReviewRequest: StoreRestaurantReviewRequest;
}

/**
 *
 */
export class RestaurantReviewsApi extends runtime.BaseAPI {
  /**
   * Index restaurant reviews.
   */
  async indexRestaurantReviewsRaw(
    requestParameters: IndexRestaurantReviewsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexRestaurantReviewResponse>> {
    const queryParameters: any = {};

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterRestaurantId !== undefined) {
      queryParameters["filter[restaurant_id]"] =
        requestParameters.filterRestaurantId;
    }

    if (requestParameters.filterIp !== undefined) {
      queryParameters["filter[ip]"] = requestParameters.filterIp;
    }

    if (requestParameters.isApproved !== undefined) {
      queryParameters["is_approved"] = requestParameters.isApproved;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/restaurant-reviews`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexRestaurantReviewResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index restaurant reviews.
   */
  async indexRestaurantReviews(
    requestParameters: IndexRestaurantReviewsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexRestaurantReviewResponse> {
    const response = await this.indexRestaurantReviewsRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show restaurant reviews by id.
   */
  async showRestaurantReviewRaw(
    requestParameters: ShowRestaurantReviewRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowRestaurantReviewResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showRestaurantReview."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/restaurant-reviews/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowRestaurantReviewResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show restaurant reviews by id.
   */
  async showRestaurantReview(
    requestParameters: ShowRestaurantReviewRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowRestaurantReviewResponse> {
    const response = await this.showRestaurantReviewRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Store banquet.
   */
  async storeRestaurantReviewRaw(
    requestParameters: StoreRestaurantReviewOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StoreRestaurantReviewResponse>> {
    if (
      requestParameters.storeRestaurantReviewRequest === null ||
      requestParameters.storeRestaurantReviewRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "storeRestaurantReviewRequest",
        "Required parameter requestParameters.storeRestaurantReviewRequest was null or undefined when calling storeRestaurantReview."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/restaurant-reviews`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StoreRestaurantReviewRequestToJSON(
          requestParameters.storeRestaurantReviewRequest
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StoreRestaurantReviewResponseFromJSON(jsonValue)
    );
  }

  /**
   * Store banquet.
   */
  async storeRestaurantReview(
    requestParameters: StoreRestaurantReviewOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StoreRestaurantReviewResponse> {
    const response = await this.storeRestaurantReviewRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
