/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Update waiter request.
 * @export
 * @interface UpdateWaiterRequest
 */
export interface UpdateWaiterRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateWaiterRequest
   */
  restaurantId?: number | null;
  /**
   *
   * @type {string}
   * @memberof UpdateWaiterRequest
   */
  uuid?: string | null;
  /**
   *
   * @type {string}
   * @memberof UpdateWaiterRequest
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWaiterRequest
   */
  surname?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateWaiterRequest
   */
  email?: string | null;
  /**
   * Phone number may start with a plus and must contain only digits 0-9.
   * @type {string}
   * @memberof UpdateWaiterRequest
   */
  phone?: string | null;
  /**
   *
   * @type {Date}
   * @memberof UpdateWaiterRequest
   */
  birthdate?: Date | null;
  /**
   *
   * @type {string}
   * @memberof UpdateWaiterRequest
   */
  about?: string | null;
}

/**
 * Check if a given object implements the UpdateWaiterRequest interface.
 */
export function instanceOfUpdateWaiterRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function UpdateWaiterRequestFromJSON(json: any): UpdateWaiterRequest {
  return UpdateWaiterRequestFromJSONTyped(json, false);
}

export function UpdateWaiterRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UpdateWaiterRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    restaurantId: !exists(json, "restaurant_id")
      ? undefined
      : json["restaurant_id"],
    uuid: !exists(json, "uuid") ? undefined : json["uuid"],
    name: !exists(json, "name") ? undefined : json["name"],
    surname: !exists(json, "surname") ? undefined : json["surname"],
    email: !exists(json, "email") ? undefined : json["email"],
    phone: !exists(json, "phone") ? undefined : json["phone"],
    birthdate: !exists(json, "birthdate")
      ? undefined
      : json["birthdate"] === null
      ? null
      : new Date(json["birthdate"]),
    about: !exists(json, "about") ? undefined : json["about"],
  };
}

export function UpdateWaiterRequestToJSON(
  value?: UpdateWaiterRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    restaurant_id: value.restaurantId,
    uuid: value.uuid,
    name: value.name,
    surname: value.surname,
    email: value.email,
    phone: value.phone,
    birthdate:
      value.birthdate === undefined
        ? undefined
        : value.birthdate === null
        ? null
        : value.birthdate.toISOString().substr(0, 10),
    about: value.about,
  };
}
