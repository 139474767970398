/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Comment } from "./Comment";
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
} from "./Comment";
import type { Discount } from "./Discount";
import {
  DiscountFromJSON,
  DiscountFromJSONTyped,
  DiscountToJSON,
} from "./Discount";

/**
 * Service order field resource object
 * @export
 * @interface ServiceOrderField
 */
export interface ServiceOrderField {
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ServiceOrderField
   */
  type: string;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  orderId: number;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  serviceId: number;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  duration: number;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  discountsAmount: number;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  discountsPercent: number;
  /**
   *
   * @type {number}
   * @memberof ServiceOrderField
   */
  discountedTotal: number;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ServiceOrderField
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {Array<Discount>}
   * @memberof ServiceOrderField
   */
  discounts?: Array<Discount>;
}

/**
 * Check if a given object implements the ServiceOrderField interface.
 */
export function instanceOfServiceOrderField(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "orderId" in value;
  isInstance = isInstance && "serviceId" in value;
  isInstance = isInstance && "amount" in value;
  isInstance = isInstance && "duration" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "discountsAmount" in value;
  isInstance = isInstance && "discountsPercent" in value;
  isInstance = isInstance && "discountedTotal" in value;

  return isInstance;
}

export function ServiceOrderFieldFromJSON(json: any): ServiceOrderField {
  return ServiceOrderFieldFromJSONTyped(json, false);
}

export function ServiceOrderFieldFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ServiceOrderField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    orderId: json["order_id"],
    serviceId: json["service_id"],
    amount: json["amount"],
    duration: json["duration"],
    total: json["total"],
    discountsAmount: json["discounts_amount"],
    discountsPercent: json["discounts_percent"],
    discountedTotal: json["discounted_total"],
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(CommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(DiscountFromJSON),
  };
}

export function ServiceOrderFieldToJSON(value?: ServiceOrderField | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    order_id: value.orderId,
    service_id: value.serviceId,
    amount: value.amount,
    duration: value.duration,
    total: value.total,
    discounts_amount: value.discountsAmount,
    discounts_percent: value.discountsPercent,
    discounted_total: value.discountedTotal,
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(CommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(DiscountToJSON),
  };
}
