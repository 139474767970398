/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Pagination meta links object.
 * @export
 * @interface Links
 */
export interface Links {
  /**
   *
   * @type {string}
   * @memberof Links
   */
  first: string;
  /**
   *
   * @type {string}
   * @memberof Links
   */
  prev: string | null;
  /**
   *
   * @type {string}
   * @memberof Links
   */
  self: string;
  /**
   *
   * @type {string}
   * @memberof Links
   */
  next: string | null;
  /**
   *
   * @type {string}
   * @memberof Links
   */
  last: string | null;
}

/**
 * Check if a given object implements the Links interface.
 */
export function instanceOfLinks(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "first" in value;
  isInstance = isInstance && "prev" in value;
  isInstance = isInstance && "self" in value;
  isInstance = isInstance && "next" in value;
  isInstance = isInstance && "last" in value;

  return isInstance;
}

export function LinksFromJSON(json: any): Links {
  return LinksFromJSONTyped(json, false);
}

export function LinksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Links {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    first: json["first"],
    prev: json["prev"],
    self: json["self"],
    next: json["next"],
    last: json["last"],
  };
}

export function LinksToJSON(value?: Links | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    first: value.first,
    prev: value.prev,
    self: value.self,
    next: value.next,
    last: value.last,
  };
}
