/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Banquet } from "./Banquet";
import {
  BanquetFromJSON,
  BanquetFromJSONTyped,
  BanquetToJSON,
} from "./Banquet";
import type { PaginationMeta } from "./PaginationMeta";
import {
  PaginationMetaFromJSON,
  PaginationMetaFromJSONTyped,
  PaginationMetaToJSON,
} from "./PaginationMeta";

/**
 * Index banquets response object.
 * @export
 * @interface IndexBanquetResponse
 */
export interface IndexBanquetResponse {
  /**
   *
   * @type {Array<Banquet>}
   * @memberof IndexBanquetResponse
   */
  data: Array<Banquet>;
  /**
   *
   * @type {PaginationMeta}
   * @memberof IndexBanquetResponse
   */
  meta: PaginationMeta;
  /**
   *
   * @type {string}
   * @memberof IndexBanquetResponse
   */
  message: string;
}

/**
 * Check if a given object implements the IndexBanquetResponse interface.
 */
export function instanceOfIndexBanquetResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "meta" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function IndexBanquetResponseFromJSON(json: any): IndexBanquetResponse {
  return IndexBanquetResponseFromJSONTyped(json, false);
}

export function IndexBanquetResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndexBanquetResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(BanquetFromJSON),
    meta: PaginationMetaFromJSON(json["meta"]),
    message: json["message"],
  };
}

export function IndexBanquetResponseToJSON(
  value?: IndexBanquetResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(BanquetToJSON),
    meta: PaginationMetaToJSON(value.meta),
    message: value.message,
  };
}
