/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Links } from "./Links";
import { LinksFromJSON, LinksFromJSONTyped, LinksToJSON } from "./Links";

/**
 * Pagination meta object.
 * @export
 * @interface PaginationMeta
 */
export interface PaginationMeta {
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  from: number | null;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  to: number | null;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  path: number | null;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  perPage: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  currentPage: number;
  /**
   *
   * @type {number}
   * @memberof PaginationMeta
   */
  lastPage: number;
  /**
   *
   * @type {Links}
   * @memberof PaginationMeta
   */
  links: Links;
}

/**
 * Check if a given object implements the PaginationMeta interface.
 */
export function instanceOfPaginationMeta(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "from" in value;
  isInstance = isInstance && "to" in value;
  isInstance = isInstance && "total" in value;
  isInstance = isInstance && "path" in value;
  isInstance = isInstance && "perPage" in value;
  isInstance = isInstance && "currentPage" in value;
  isInstance = isInstance && "lastPage" in value;
  isInstance = isInstance && "links" in value;

  return isInstance;
}

export function PaginationMetaFromJSON(json: any): PaginationMeta {
  return PaginationMetaFromJSONTyped(json, false);
}

export function PaginationMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PaginationMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    from: json["from"],
    to: json["to"],
    total: json["total"],
    path: json["path"],
    perPage: json["per_page"],
    currentPage: json["current_page"],
    lastPage: json["last_page"],
    links: LinksFromJSON(json["links"]),
  };
}

export function PaginationMetaToJSON(value?: PaginationMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    from: value.from,
    to: value.to,
    total: value.total,
    path: value.path,
    per_page: value.perPage,
    current_page: value.currentPage,
    last_page: value.lastPage,
    links: LinksToJSON(value.links),
  };
}
