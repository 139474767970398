/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Comment resource object
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  text: string;
  /**
   *
   * @type {number}
   * @memberof Comment
   */
  commentableId: number;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  commentableType: string;
}

/**
 * Check if a given object implements the Comment interface.
 */
export function instanceOfComment(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "text" in value;
  isInstance = isInstance && "commentableId" in value;
  isInstance = isInstance && "commentableType" in value;

  return isInstance;
}

export function CommentFromJSON(json: any): Comment {
  return CommentFromJSONTyped(json, false);
}

export function CommentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Comment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    text: json["text"],
    commentableId: json["commentable_id"],
    commentableType: json["commentable_type"],
  };
}

export function CommentToJSON(value?: Comment | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    text: value.text,
    commentable_id: value.commentableId,
    commentable_type: value.commentableType,
  };
}
