/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Delete customer response object.
 * @export
 * @interface DestroyCustomerResponse
 */
export interface DestroyCustomerResponse {
  /**
   *
   * @type {string}
   * @memberof DestroyCustomerResponse
   */
  message: string;
}

/**
 * Check if a given object implements the DestroyCustomerResponse interface.
 */
export function instanceOfDestroyCustomerResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function DestroyCustomerResponseFromJSON(
  json: any
): DestroyCustomerResponse {
  return DestroyCustomerResponseFromJSONTyped(json, false);
}

export function DestroyCustomerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DestroyCustomerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json["message"],
  };
}

export function DestroyCustomerResponseToJSON(
  value?: DestroyCustomerResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
  };
}
