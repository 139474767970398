/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Order } from "./Order";
import { OrderFromJSON, OrderFromJSONTyped, OrderToJSON } from "./Order";
import type { PaginationMeta } from "./PaginationMeta";
import {
  PaginationMetaFromJSON,
  PaginationMetaFromJSONTyped,
  PaginationMetaToJSON,
} from "./PaginationMeta";

/**
 * Index orders response object.
 * @export
 * @interface IndexOrderResponse
 */
export interface IndexOrderResponse {
  /**
   *
   * @type {Array<Order>}
   * @memberof IndexOrderResponse
   */
  data: Array<Order>;
  /**
   *
   * @type {PaginationMeta}
   * @memberof IndexOrderResponse
   */
  meta: PaginationMeta;
  /**
   *
   * @type {string}
   * @memberof IndexOrderResponse
   */
  message: string;
}

/**
 * Check if a given object implements the IndexOrderResponse interface.
 */
export function instanceOfIndexOrderResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "meta" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function IndexOrderResponseFromJSON(json: any): IndexOrderResponse {
  return IndexOrderResponseFromJSONTyped(json, false);
}

export function IndexOrderResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IndexOrderResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json["data"] as Array<any>).map(OrderFromJSON),
    meta: PaginationMetaFromJSON(json["meta"]),
    message: json["message"],
  };
}

export function IndexOrderResponseToJSON(
  value?: IndexOrderResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrderToJSON),
    meta: PaginationMetaToJSON(value.meta),
    message: value.message,
  };
}
