/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Comment } from "./Comment";
import {
  CommentFromJSON,
  CommentFromJSONTyped,
  CommentToJSON,
} from "./Comment";
import type { Customer } from "./Customer";
import {
  CustomerFromJSON,
  CustomerFromJSONTyped,
  CustomerToJSON,
} from "./Customer";
import type { Discount } from "./Discount";
import {
  DiscountFromJSON,
  DiscountFromJSONTyped,
  DiscountToJSON,
} from "./Discount";
import type { Order } from "./Order";
import { OrderFromJSON, OrderFromJSONTyped, OrderToJSON } from "./Order";
import type { OrderTotals } from "./OrderTotals";
import {
  OrderTotalsFromJSON,
  OrderTotalsFromJSONTyped,
  OrderTotalsToJSON,
} from "./OrderTotals";
import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 * Banquet resource object
 * @export
 * @interface Banquet
 */
export interface Banquet {
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Banquet
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Banquet
   */
  state: BanquetStateEnum;
  /**
   * List of states, to which banquet may be transferred.
   * @type {Array<string>}
   * @memberof Banquet
   */
  availableStates: Array<BanquetAvailableStatesEnum>;
  /**
   * Determines if banquet can be edited.
   * @type {boolean}
   * @memberof Banquet
   */
  isEditable: boolean;
  /**
   * Determines if logged in user can edit this banquet.
   * @type {boolean}
   * @memberof Banquet
   */
  canEdit: boolean;
  /**
   *
   * @type {string}
   * @memberof Banquet
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Banquet
   */
  description: string | null;
  /**
   *
   * @type {Date}
   * @memberof Banquet
   */
  startAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Banquet
   */
  endAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Banquet
   */
  paidAt: Date | null;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  advanceAmount: number;
  /**
   *
   * @type {string}
   * @memberof Banquet
   */
  advanceAmountPaymentMethod: string | null;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  actualTotal: number | null;
  /**
   *
   * @type {boolean}
   * @memberof Banquet
   */
  isBirthdayClub: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof Banquet
   */
  withPhotographer: boolean | null;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  childrenAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  childTicketPrice: number | null;
  /**
   *
   * @type {Array<number>}
   * @memberof Banquet
   */
  childrenAmounts?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof Banquet
   */
  childTicketPrices?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  adultsAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  adultTicketPrice: number | null;
  /**
   *
   * @type {OrderTotals}
   * @memberof Banquet
   */
  totals: OrderTotals;
  /**
   *
   * @type {string}
   * @memberof Banquet
   */
  invoiceUrl: string | null;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  orderId: number | null;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  creatorId: number;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  customerId: number;
  /**
   *
   * @type {number}
   * @memberof Banquet
   */
  restaurantId: number | null;
  /**
   *
   * @type {Order}
   * @memberof Banquet
   */
  order?: Order;
  /**
   *
   * @type {User}
   * @memberof Banquet
   */
  creator?: User;
  /**
   *
   * @type {Customer}
   * @memberof Banquet
   */
  customer?: Customer;
  /**
   *
   * @type {Array<Comment>}
   * @memberof Banquet
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {Array<Discount>}
   * @memberof Banquet
   */
  discounts?: Array<Discount>;
}

/**
 * @export
 */
export const BanquetStateEnum = {
  New: "new",
  Confirmed: "confirmed",
  Postponed: "postponed",
  Cancelled: "cancelled",
  Completed: "completed",
} as const;
export type BanquetStateEnum =
  (typeof BanquetStateEnum)[keyof typeof BanquetStateEnum];

/**
 * @export
 */
export const BanquetAvailableStatesEnum = {
  Draft: "draft",
  New: "new",
  Processing: "processing",
  Completed: "completed",
  Cancelled: "cancelled",
} as const;
export type BanquetAvailableStatesEnum =
  (typeof BanquetAvailableStatesEnum)[keyof typeof BanquetAvailableStatesEnum];

/**
 * Check if a given object implements the Banquet interface.
 */
export function instanceOfBanquet(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "availableStates" in value;
  isInstance = isInstance && "isEditable" in value;
  isInstance = isInstance && "canEdit" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "startAt" in value;
  isInstance = isInstance && "endAt" in value;
  isInstance = isInstance && "paidAt" in value;
  isInstance = isInstance && "advanceAmount" in value;
  isInstance = isInstance && "advanceAmountPaymentMethod" in value;
  isInstance = isInstance && "actualTotal" in value;
  isInstance = isInstance && "isBirthdayClub" in value;
  isInstance = isInstance && "withPhotographer" in value;
  isInstance = isInstance && "childrenAmount" in value;
  isInstance = isInstance && "childTicketPrice" in value;
  isInstance = isInstance && "adultsAmount" in value;
  isInstance = isInstance && "adultTicketPrice" in value;
  isInstance = isInstance && "totals" in value;
  isInstance = isInstance && "invoiceUrl" in value;
  isInstance = isInstance && "orderId" in value;
  isInstance = isInstance && "creatorId" in value;
  isInstance = isInstance && "customerId" in value;
  isInstance = isInstance && "restaurantId" in value;

  return isInstance;
}

export function BanquetFromJSON(json: any): Banquet {
  return BanquetFromJSONTyped(json, false);
}

export function BanquetFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Banquet {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    state: json["state"],
    availableStates: json["available_states"],
    isEditable: json["is_editable"],
    canEdit: json["can_edit"],
    title: json["title"],
    description: json["description"],
    startAt: new Date(json["start_at"]),
    endAt: new Date(json["end_at"]),
    paidAt: json["paid_at"] === null ? null : new Date(json["paid_at"]),
    advanceAmount: json["advance_amount"],
    advanceAmountPaymentMethod: json["advance_amount_payment_method"],
    actualTotal: json["actual_total"],
    isBirthdayClub: json["is_birthday_club"],
    withPhotographer: json["with_photographer"],
    childrenAmount: json["children_amount"],
    childTicketPrice: json["child_ticket_price"],
    childrenAmounts: !exists(json, "children_amounts")
      ? undefined
      : json["children_amounts"],
    childTicketPrices: !exists(json, "child_ticket_prices")
      ? undefined
      : json["child_ticket_prices"],
    adultsAmount: json["adults_amount"],
    adultTicketPrice: json["adult_ticket_price"],
    totals: OrderTotalsFromJSON(json["totals"]),
    invoiceUrl: json["invoice_url"],
    orderId: json["order_id"],
    creatorId: json["creator_id"],
    customerId: json["customer_id"],
    restaurantId: json["restaurant_id"],
    order: !exists(json, "order") ? undefined : OrderFromJSON(json["order"]),
    creator: !exists(json, "creator")
      ? undefined
      : UserFromJSON(json["creator"]),
    customer: !exists(json, "customer")
      ? undefined
      : CustomerFromJSON(json["customer"]),
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(CommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(DiscountFromJSON),
  };
}

export function BanquetToJSON(value?: Banquet | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    state: value.state,
    available_states: value.availableStates,
    is_editable: value.isEditable,
    can_edit: value.canEdit,
    title: value.title,
    description: value.description,
    start_at: value.startAt.toISOString(),
    end_at: value.endAt.toISOString(),
    paid_at: value.paidAt === null ? null : value.paidAt.toISOString(),
    advance_amount: value.advanceAmount,
    advance_amount_payment_method: value.advanceAmountPaymentMethod,
    actual_total: value.actualTotal,
    is_birthday_club: value.isBirthdayClub,
    with_photographer: value.withPhotographer,
    children_amount: value.childrenAmount,
    child_ticket_price: value.childTicketPrice,
    children_amounts: value.childrenAmounts,
    child_ticket_prices: value.childTicketPrices,
    adults_amount: value.adultsAmount,
    adult_ticket_price: value.adultTicketPrice,
    totals: OrderTotalsToJSON(value.totals),
    invoice_url: value.invoiceUrl,
    order_id: value.orderId,
    creator_id: value.creatorId,
    customer_id: value.customerId,
    restaurant_id: value.restaurantId,
    order: OrderToJSON(value.order),
    creator: UserToJSON(value.creator),
    customer: CustomerToJSON(value.customer),
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(CommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(DiscountToJSON),
  };
}
