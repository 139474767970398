/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AlternationAlterable } from "./AlternationAlterable";
import {
  AlternationAlterableFromJSON,
  AlternationAlterableFromJSONTyped,
  AlternationAlterableToJSON,
} from "./AlternationAlterable";

/**
 * Alternation resource object
 * @export
 * @interface Alternation
 */
export interface Alternation {
  /**
   *
   * @type {number}
   * @memberof Alternation
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Alternation
   */
  type: string;
  /**
   *
   * @type {object}
   * @memberof Alternation
   */
  metadata: object | null;
  /**
   *
   * @type {number}
   * @memberof Alternation
   */
  alterableId: number;
  /**
   *
   * @type {string}
   * @memberof Alternation
   */
  alterableType: string;
  /**
   *
   * @type {Date}
   * @memberof Alternation
   */
  performAt: Date | null;
  /**
   *
   * @type {Date}
   * @memberof Alternation
   */
  performedAt: Date | null;
  /**
   *
   * @type {AlternationAlterable}
   * @memberof Alternation
   */
  alterable?: AlternationAlterable;
}

/**
 * Check if a given object implements the Alternation interface.
 */
export function instanceOfAlternation(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "metadata" in value;
  isInstance = isInstance && "alterableId" in value;
  isInstance = isInstance && "alterableType" in value;
  isInstance = isInstance && "performAt" in value;
  isInstance = isInstance && "performedAt" in value;

  return isInstance;
}

export function AlternationFromJSON(json: any): Alternation {
  return AlternationFromJSONTyped(json, false);
}

export function AlternationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Alternation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    metadata: json["metadata"],
    alterableId: json["alterable_id"],
    alterableType: json["alterable_type"],
    performAt:
      json["perform_at"] === null ? null : new Date(json["perform_at"]),
    performedAt:
      json["performed_at"] === null ? null : new Date(json["performed_at"]),
    alterable: !exists(json, "alterable")
      ? undefined
      : AlternationAlterableFromJSON(json["alterable"]),
  };
}

export function AlternationToJSON(value?: Alternation | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    metadata: value.metadata,
    alterable_id: value.alterableId,
    alterable_type: value.alterableType,
    perform_at: value.performAt === null ? null : value.performAt.toISOString(),
    performed_at:
      value.performedAt === null ? null : value.performedAt.toISOString(),
    alterable: AlternationAlterableToJSON(value.alterable),
  };
}
