/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DestroyOrderResponse,
  DestroyRequest,
  IndexOrderResponse,
  RestoreOrderResponse,
  ShowOrderResponse,
  StoreOrderRequest,
  StoreOrderResponse,
  UnauthenticatedResponse,
  UpdateOrderRequest,
  UpdateOrderResponse,
} from "../models";
import {
  DestroyOrderResponseFromJSON,
  DestroyOrderResponseToJSON,
  DestroyRequestFromJSON,
  DestroyRequestToJSON,
  IndexOrderResponseFromJSON,
  IndexOrderResponseToJSON,
  RestoreOrderResponseFromJSON,
  RestoreOrderResponseToJSON,
  ShowOrderResponseFromJSON,
  ShowOrderResponseToJSON,
  StoreOrderRequestFromJSON,
  StoreOrderRequestToJSON,
  StoreOrderResponseFromJSON,
  StoreOrderResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
  UpdateOrderRequestFromJSON,
  UpdateOrderRequestToJSON,
  UpdateOrderResponseFromJSON,
  UpdateOrderResponseToJSON,
} from "../models";

export interface DestroyOrderRequest {
  id: number;
  destroyRequest?: DestroyRequest;
}

export interface IndexOrdersRequest {
  include?: string;
  pageSize?: number;
  pageNumber?: number;
}

export interface RestoreOrderRequest {
  id: number;
}

export interface ShowOrderRequest {
  id: number;
  include?: string;
}

export interface StoreOrderOperationRequest {
  storeOrderRequest: StoreOrderRequest;
}

export interface UpdateOrderOperationRequest {
  id: number;
  updateOrderRequest: UpdateOrderRequest;
}

/**
 *
 */
export class OrdersApi extends runtime.BaseAPI {
  /**
   * Delete order.
   */
  async destroyOrderRaw(
    requestParameters: DestroyOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DestroyOrderResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling destroyOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
        body: DestroyRequestToJSON(requestParameters.destroyRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DestroyOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete order.
   */
  async destroyOrder(
    requestParameters: DestroyOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DestroyOrderResponse> {
    const response = await this.destroyOrderRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Index orders.
   */
  async indexOrdersRaw(
    requestParameters: IndexOrdersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexOrderResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index orders.
   */
  async indexOrders(
    requestParameters: IndexOrdersRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexOrderResponse> {
    const response = await this.indexOrdersRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Restore order.
   */
  async restoreOrderRaw(
    requestParameters: RestoreOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RestoreOrderResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling restoreOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/{id}/restore`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RestoreOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * Restore order.
   */
  async restoreOrder(
    requestParameters: RestoreOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RestoreOrderResponse> {
    const response = await this.restoreOrderRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show orders by id.
   */
  async showOrderRaw(
    requestParameters: ShowOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowOrderResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showOrder."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show orders by id.
   */
  async showOrder(
    requestParameters: ShowOrderRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowOrderResponse> {
    const response = await this.showOrderRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Store order.
   */
  async storeOrderRaw(
    requestParameters: StoreOrderOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<StoreOrderResponse>> {
    if (
      requestParameters.storeOrderRequest === null ||
      requestParameters.storeOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "storeOrderRequest",
        "Required parameter requestParameters.storeOrderRequest was null or undefined when calling storeOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: StoreOrderRequestToJSON(requestParameters.storeOrderRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      StoreOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * Store order.
   */
  async storeOrder(
    requestParameters: StoreOrderOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<StoreOrderResponse> {
    const response = await this.storeOrderRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update order.
   */
  async updateOrderRaw(
    requestParameters: UpdateOrderOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UpdateOrderResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateOrder."
      );
    }

    if (
      requestParameters.updateOrderRequest === null ||
      requestParameters.updateOrderRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "updateOrderRequest",
        "Required parameter requestParameters.updateOrderRequest was null or undefined when calling updateOrder."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/orders/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: UpdateOrderRequestToJSON(requestParameters.updateOrderRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UpdateOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update order.
   */
  async updateOrder(
    requestParameters: UpdateOrderOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UpdateOrderResponse> {
    const response = await this.updateOrderRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
