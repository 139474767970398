/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ArchivedParameter,
  IndexServiceResponse,
  ShowServiceResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  ArchivedParameterFromJSON,
  ArchivedParameterToJSON,
  IndexServiceResponseFromJSON,
  IndexServiceResponseToJSON,
  ShowServiceResponseFromJSON,
  ShowServiceResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface IndexServicesRequest {
  include?: string;
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  filterIds?: string;
  filterTitle?: string;
  filterCategories?: string;
  filterRestaurants?: string;
  archived?: ArchivedParameter;
}

export interface ShowServiceRequest {
  id: number;
  include?: string;
}

/**
 *
 */
export class ServicesApi extends runtime.BaseAPI {
  /**
   * Index services.
   */
  async indexServicesRaw(
    requestParameters: IndexServicesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexServiceResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterIds !== undefined) {
      queryParameters["filter[ids]"] = requestParameters.filterIds;
    }

    if (requestParameters.filterTitle !== undefined) {
      queryParameters["filter[title]"] = requestParameters.filterTitle;
    }

    if (requestParameters.filterCategories !== undefined) {
      queryParameters["filter[categories]"] =
        requestParameters.filterCategories;
    }

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/services`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexServiceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index services.
   */
  async indexServices(
    requestParameters: IndexServicesRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexServiceResponse> {
    const response = await this.indexServicesRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Show service by id.
   */
  async showServiceRaw(
    requestParameters: ShowServiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowServiceResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showService."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/services/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowServiceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show service by id.
   */
  async showService(
    requestParameters: ShowServiceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowServiceResponse> {
    const response = await this.showServiceRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
