/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Generate url for accessing banquet's invoice response.
 * @export
 * @interface BanquetInvoiceUrlResponse
 */
export interface BanquetInvoiceUrlResponse {
  /**
   *
   * @type {string}
   * @memberof BanquetInvoiceUrlResponse
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof BanquetInvoiceUrlResponse
   */
  message: string;
}

/**
 * Check if a given object implements the BanquetInvoiceUrlResponse interface.
 */
export function instanceOfBanquetInvoiceUrlResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "url" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function BanquetInvoiceUrlResponseFromJSON(
  json: any
): BanquetInvoiceUrlResponse {
  return BanquetInvoiceUrlResponseFromJSONTyped(json, false);
}

export function BanquetInvoiceUrlResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BanquetInvoiceUrlResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    url: json["url"],
    message: json["message"],
  };
}

export function BanquetInvoiceUrlResponseToJSON(
  value?: BanquetInvoiceUrlResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    url: value.url,
    message: value.message,
  };
}
