/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Generate url for accessing banquet's invoice request
 * @export
 * @interface BanquetInvoiceUrlRequest
 */
export interface BanquetInvoiceUrlRequest {
  /**
   *
   * @type {string}
   * @memberof BanquetInvoiceUrlRequest
   */
  endpoint?: BanquetInvoiceUrlRequestEndpointEnum;
}

/**
 * @export
 */
export const BanquetInvoiceUrlRequestEndpointEnum = {
  PdfThroughBanquet: "pdfThroughBanquet",
  ViewThroughBanquet: "viewThroughBanquet",
} as const;
export type BanquetInvoiceUrlRequestEndpointEnum =
  (typeof BanquetInvoiceUrlRequestEndpointEnum)[keyof typeof BanquetInvoiceUrlRequestEndpointEnum];

/**
 * Check if a given object implements the BanquetInvoiceUrlRequest interface.
 */
export function instanceOfBanquetInvoiceUrlRequest(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function BanquetInvoiceUrlRequestFromJSON(
  json: any
): BanquetInvoiceUrlRequest {
  return BanquetInvoiceUrlRequestFromJSONTyped(json, false);
}

export function BanquetInvoiceUrlRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BanquetInvoiceUrlRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    endpoint: !exists(json, "endpoint") ? undefined : json["endpoint"],
  };
}

export function BanquetInvoiceUrlRequestToJSON(
  value?: BanquetInvoiceUrlRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    endpoint: value.endpoint,
  };
}
