/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  ArchivedParameter,
  IndexMenuResponse,
  ShowMenuResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  ArchivedParameterFromJSON,
  ArchivedParameterToJSON,
  IndexMenuResponseFromJSON,
  IndexMenuResponseToJSON,
  ShowMenuResponseFromJSON,
  ShowMenuResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface IndexMenusRequest {
  include?: string;
  pageSize?: number;
  pageNumber?: number;
  sort?: string;
  filterRestaurants?: string;
  archived?: ArchivedParameter;
}

export interface ShowMenuRequest {
  id: number;
  include?: string;
}

/**
 *
 */
export class MenusApi extends runtime.BaseAPI {
  /**
   * Index menus.
   */
  async indexMenusRaw(
    requestParameters: IndexMenusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexMenuResponse>> {
    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.archived !== undefined) {
      queryParameters["archived"] = requestParameters.archived;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index menus.
   */
  async indexMenus(
    requestParameters: IndexMenusRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexMenuResponse> {
    const response = await this.indexMenusRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Show menus by id.
   */
  async showMenuRaw(
    requestParameters: ShowMenuRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowMenuResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showMenu."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.include !== undefined) {
      queryParameters["include"] = requestParameters.include;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/menus/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowMenuResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show menus by id.
   */
  async showMenu(
    requestParameters: ShowMenuRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowMenuResponse> {
    const response = await this.showMenuRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
