/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { AttachingComment } from "./AttachingComment";
import {
  AttachingCommentFromJSON,
  AttachingCommentFromJSONTyped,
  AttachingCommentToJSON,
} from "./AttachingComment";
import type { AttachingDiscount } from "./AttachingDiscount";
import {
  AttachingDiscountFromJSON,
  AttachingDiscountFromJSONTyped,
  AttachingDiscountToJSON,
} from "./AttachingDiscount";

/**
 * Store banquet request
 * @export
 * @interface StoreBanquetRequest
 */
export interface StoreBanquetRequest {
  /**
   * If null, then title will be autogenerated as Banquet-ID.
   * @type {string}
   * @memberof StoreBanquetRequest
   */
  title: string | null;
  /**
   *
   * @type {string}
   * @memberof StoreBanquetRequest
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof StoreBanquetRequest
   */
  state: StoreBanquetRequestStateEnum;
  /**
   * Id of the user, who created banquet.
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  creatorId?: number;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  customerId: number;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  restaurantId?: number | null;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  advanceAmount?: number;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  actualTotal?: number | null;
  /**
   *
   * @type {string}
   * @memberof StoreBanquetRequest
   */
  advanceAmountPaymentMethod?: StoreBanquetRequestAdvanceAmountPaymentMethodEnum;
  /**
   *
   * @type {boolean}
   * @memberof StoreBanquetRequest
   */
  isBirthdayClub?: boolean | null;
  /**
   *
   * @type {boolean}
   * @memberof StoreBanquetRequest
   */
  withPhotographer?: boolean | null;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  childrenAmount?: number | null;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  childTicketPrice?: number | null;
  /**
   *
   * @type {Array<number>}
   * @memberof StoreBanquetRequest
   */
  childrenAmounts?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof StoreBanquetRequest
   */
  childTicketPrices?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  adultsAmount?: number | null;
  /**
   *
   * @type {number}
   * @memberof StoreBanquetRequest
   */
  adultTicketPrice?: number | null;
  /**
   * Date and time of when banquet should start.
   * @type {Date}
   * @memberof StoreBanquetRequest
   */
  startAt: Date;
  /**
   * Date and time of when banquet should end. Must be after or equal to `start_at`.
   * @type {Date}
   * @memberof StoreBanquetRequest
   */
  endAt: Date;
  /**
   * Date and time of when banquet was fully paid for.
   * @type {Date}
   * @memberof StoreBanquetRequest
   */
  paidAt?: Date | null;
  /**
   *
   * @type {Array<AttachingComment>}
   * @memberof StoreBanquetRequest
   */
  comments?: Array<AttachingComment>;
  /**
   *
   * @type {Array<AttachingDiscount>}
   * @memberof StoreBanquetRequest
   */
  discounts?: Array<AttachingDiscount>;
}

/**
 * @export
 */
export const StoreBanquetRequestStateEnum = {
  New: "new",
} as const;
export type StoreBanquetRequestStateEnum =
  (typeof StoreBanquetRequestStateEnum)[keyof typeof StoreBanquetRequestStateEnum];

/**
 * @export
 */
export const StoreBanquetRequestAdvanceAmountPaymentMethodEnum = {
  Card: "card",
  Cash: "cash",
} as const;
export type StoreBanquetRequestAdvanceAmountPaymentMethodEnum =
  (typeof StoreBanquetRequestAdvanceAmountPaymentMethodEnum)[keyof typeof StoreBanquetRequestAdvanceAmountPaymentMethodEnum];

/**
 * Check if a given object implements the StoreBanquetRequest interface.
 */
export function instanceOfStoreBanquetRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "state" in value;
  isInstance = isInstance && "customerId" in value;
  isInstance = isInstance && "startAt" in value;
  isInstance = isInstance && "endAt" in value;

  return isInstance;
}

export function StoreBanquetRequestFromJSON(json: any): StoreBanquetRequest {
  return StoreBanquetRequestFromJSONTyped(json, false);
}

export function StoreBanquetRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): StoreBanquetRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json["title"],
    description: json["description"],
    state: json["state"],
    creatorId: !exists(json, "creator_id") ? undefined : json["creator_id"],
    customerId: json["customer_id"],
    restaurantId: !exists(json, "restaurant_id")
      ? undefined
      : json["restaurant_id"],
    advanceAmount: !exists(json, "advance_amount")
      ? undefined
      : json["advance_amount"],
    actualTotal: !exists(json, "actual_total")
      ? undefined
      : json["actual_total"],
    advanceAmountPaymentMethod: !exists(json, "advance_amount_payment_method")
      ? undefined
      : json["advance_amount_payment_method"],
    isBirthdayClub: !exists(json, "is_birthday_club")
      ? undefined
      : json["is_birthday_club"],
    withPhotographer: !exists(json, "with_photographer")
      ? undefined
      : json["with_photographer"],
    childrenAmount: !exists(json, "children_amount")
      ? undefined
      : json["children_amount"],
    childTicketPrice: !exists(json, "child_ticket_price")
      ? undefined
      : json["child_ticket_price"],
    childrenAmounts: !exists(json, "children_amounts")
      ? undefined
      : json["children_amounts"],
    childTicketPrices: !exists(json, "child_ticket_prices")
      ? undefined
      : json["child_ticket_prices"],
    adultsAmount: !exists(json, "adults_amount")
      ? undefined
      : json["adults_amount"],
    adultTicketPrice: !exists(json, "adult_ticket_price")
      ? undefined
      : json["adult_ticket_price"],
    startAt: new Date(json["start_at"]),
    endAt: new Date(json["end_at"]),
    paidAt: !exists(json, "paid_at")
      ? undefined
      : json["paid_at"] === null
      ? null
      : new Date(json["paid_at"]),
    comments: !exists(json, "comments")
      ? undefined
      : (json["comments"] as Array<any>).map(AttachingCommentFromJSON),
    discounts: !exists(json, "discounts")
      ? undefined
      : (json["discounts"] as Array<any>).map(AttachingDiscountFromJSON),
  };
}

export function StoreBanquetRequestToJSON(
  value?: StoreBanquetRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    state: value.state,
    creator_id: value.creatorId,
    customer_id: value.customerId,
    restaurant_id: value.restaurantId,
    advance_amount: value.advanceAmount,
    actual_total: value.actualTotal,
    advance_amount_payment_method: value.advanceAmountPaymentMethod,
    is_birthday_club: value.isBirthdayClub,
    with_photographer: value.withPhotographer,
    children_amount: value.childrenAmount,
    child_ticket_price: value.childTicketPrice,
    children_amounts: value.childrenAmounts,
    child_ticket_prices: value.childTicketPrices,
    adults_amount: value.adultsAmount,
    adult_ticket_price: value.adultTicketPrice,
    start_at: value.startAt.toISOString(),
    end_at: value.endAt.toISOString(),
    paid_at:
      value.paidAt === undefined
        ? undefined
        : value.paidAt === null
        ? null
        : value.paidAt.toISOString(),
    comments:
      value.comments === undefined
        ? undefined
        : (value.comments as Array<any>).map(AttachingCommentToJSON),
    discounts:
      value.discounts === undefined
        ? undefined
        : (value.discounts as Array<any>).map(AttachingDiscountToJSON),
  };
}
