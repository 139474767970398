/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Alternation } from "./Alternation";
import {
  AlternationFromJSON,
  AlternationFromJSONTyped,
  AlternationToJSON,
} from "./Alternation";
import type { Category } from "./Category";
import {
  CategoryFromJSON,
  CategoryFromJSONTyped,
  CategoryToJSON,
} from "./Category";
import type { Media } from "./Media";
import { MediaFromJSON, MediaFromJSONTyped, MediaToJSON } from "./Media";
import type { ProductVariant } from "./ProductVariant";
import {
  ProductVariantFromJSON,
  ProductVariantFromJSONTyped,
  ProductVariantToJSON,
} from "./ProductVariant";
import type { Tag } from "./Tag";
import { TagFromJSON, TagFromJSONTyped, TagToJSON } from "./Tag";

/**
 * Product resource object
 * @export
 * @interface Product
 */
export interface Product {
  /**
   *
   * @type {number}
   * @memberof Product
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  description: string | null;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  weight: number | null;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  weightUnit: ProductWeightUnitEnum;
  /**
   *
   * @type {string}
   * @memberof Product
   */
  badge: string | null;
  /**
   *
   * @type {boolean}
   * @memberof Product
   */
  archived: boolean;
  /**
   *
   * @type {number}
   * @memberof Product
   */
  popularity: number | null;
  /**
   *
   * @type {Array<ProductVariant>}
   * @memberof Product
   */
  variants: Array<ProductVariant>;
  /**
   *
   * @type {Array<Category>}
   * @memberof Product
   */
  categories?: Array<Category>;
  /**
   *
   * @type {Array<number>}
   * @memberof Product
   */
  categoryIds: Array<number>;
  /**
   *
   * @type {Array<Tag>}
   * @memberof Product
   */
  tags?: Array<Tag>;
  /**
   *
   * @type {Array<Media>}
   * @memberof Product
   */
  media: Array<Media>;
  /**
   *
   * @type {Array<Alternation>}
   * @memberof Product
   */
  alterations?: Array<Alternation>;
  /**
   *
   * @type {Array<Alternation>}
   * @memberof Product
   */
  pendingAlterations?: Array<Alternation>;
  /**
   *
   * @type {Array<Alternation>}
   * @memberof Product
   */
  performedAlterations?: Array<Alternation>;
}

/**
 * @export
 */
export const ProductWeightUnitEnum = {
  G: "g",
  Kg: "kg",
  Ml: "ml",
  L: "l",
  Cm: "cm",
} as const;
export type ProductWeightUnitEnum =
  (typeof ProductWeightUnitEnum)[keyof typeof ProductWeightUnitEnum];

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "price" in value;
  isInstance = isInstance && "weight" in value;
  isInstance = isInstance && "weightUnit" in value;
  isInstance = isInstance && "badge" in value;
  isInstance = isInstance && "archived" in value;
  isInstance = isInstance && "popularity" in value;
  isInstance = isInstance && "variants" in value;
  isInstance = isInstance && "categoryIds" in value;
  isInstance = isInstance && "media" in value;

  return isInstance;
}

export function ProductFromJSON(json: any): Product {
  return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Product {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    title: json["title"],
    description: json["description"],
    price: json["price"],
    weight: json["weight"],
    weightUnit: json["weight_unit"],
    badge: json["badge"],
    archived: json["archived"],
    popularity: json["popularity"],
    variants: (json["variants"] as Array<any>).map(ProductVariantFromJSON),
    categories: !exists(json, "categories")
      ? undefined
      : (json["categories"] as Array<any>).map(CategoryFromJSON),
    categoryIds: json["category_ids"],
    tags: !exists(json, "tags")
      ? undefined
      : (json["tags"] as Array<any>).map(TagFromJSON),
    media: (json["media"] as Array<any>).map(MediaFromJSON),
    alterations: !exists(json, "alterations")
      ? undefined
      : (json["alterations"] as Array<any>).map(AlternationFromJSON),
    pendingAlterations: !exists(json, "pendingAlterations")
      ? undefined
      : (json["pendingAlterations"] as Array<any>).map(AlternationFromJSON),
    performedAlterations: !exists(json, "performedAlterations")
      ? undefined
      : (json["performedAlterations"] as Array<any>).map(AlternationFromJSON),
  };
}

export function ProductToJSON(value?: Product | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    title: value.title,
    description: value.description,
    price: value.price,
    weight: value.weight,
    weight_unit: value.weightUnit,
    badge: value.badge,
    archived: value.archived,
    popularity: value.popularity,
    variants: (value.variants as Array<any>).map(ProductVariantToJSON),
    categories:
      value.categories === undefined
        ? undefined
        : (value.categories as Array<any>).map(CategoryToJSON),
    category_ids: value.categoryIds,
    tags:
      value.tags === undefined
        ? undefined
        : (value.tags as Array<any>).map(TagToJSON),
    media: (value.media as Array<any>).map(MediaToJSON),
    alterations:
      value.alterations === undefined
        ? undefined
        : (value.alterations as Array<any>).map(AlternationToJSON),
    pendingAlterations:
      value.pendingAlterations === undefined
        ? undefined
        : (value.pendingAlterations as Array<any>).map(AlternationToJSON),
    performedAlterations:
      value.performedAlterations === undefined
        ? undefined
        : (value.performedAlterations as Array<any>).map(AlternationToJSON),
  };
}
