/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 * User and token response object.
 * @export
 * @interface UserAndTokenResponse
 */
export interface UserAndTokenResponse {
  /**
   *
   * @type {string}
   * @memberof UserAndTokenResponse
   */
  token: string;
  /**
   *
   * @type {User}
   * @memberof UserAndTokenResponse
   */
  user: User;
}

/**
 * Check if a given object implements the UserAndTokenResponse interface.
 */
export function instanceOfUserAndTokenResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "token" in value;
  isInstance = isInstance && "user" in value;

  return isInstance;
}

export function UserAndTokenResponseFromJSON(json: any): UserAndTokenResponse {
  return UserAndTokenResponseFromJSONTyped(json, false);
}

export function UserAndTokenResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserAndTokenResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: json["token"],
    user: UserFromJSON(json["user"]),
  };
}

export function UserAndTokenResponseToJSON(
  value?: UserAndTokenResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
    user: UserToJSON(value.user),
  };
}
