/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

/**
 * Show user response object.
 * @export
 * @interface ShowUserResponse
 */
export interface ShowUserResponse {
  /**
   *
   * @type {User}
   * @memberof ShowUserResponse
   */
  data: User;
  /**
   *
   * @type {string}
   * @memberof ShowUserResponse
   */
  message: string;
}

/**
 * Check if a given object implements the ShowUserResponse interface.
 */
export function instanceOfShowUserResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function ShowUserResponseFromJSON(json: any): ShowUserResponse {
  return ShowUserResponseFromJSONTyped(json, false);
}

export function ShowUserResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShowUserResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: UserFromJSON(json["data"]),
    message: json["message"],
  };
}

export function ShowUserResponseToJSON(value?: ShowUserResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: UserToJSON(value.data),
    message: value.message,
  };
}
