/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  IndexTagResponse,
  ShowTagResponse,
  UnauthenticatedResponse,
} from "../models";
import {
  IndexTagResponseFromJSON,
  IndexTagResponseToJSON,
  ShowTagResponseFromJSON,
  ShowTagResponseToJSON,
  UnauthenticatedResponseFromJSON,
  UnauthenticatedResponseToJSON,
} from "../models";

export interface IndexTagsRequest {
  pageSize?: number;
  pageNumber?: number;
  filterRestaurants?: string;
  filterTarget?: string;
}

export interface ShowTagRequest {
  id: number;
}

/**
 *
 */
export class TagsApi extends runtime.BaseAPI {
  /**
   * Index tags.
   */
  async indexTagsRaw(
    requestParameters: IndexTagsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IndexTagResponse>> {
    const queryParameters: any = {};

    if (requestParameters.pageSize !== undefined) {
      queryParameters["page[size]"] = requestParameters.pageSize;
    }

    if (requestParameters.pageNumber !== undefined) {
      queryParameters["page[number]"] = requestParameters.pageNumber;
    }

    if (requestParameters.filterRestaurants !== undefined) {
      queryParameters["filter[restaurants]"] =
        requestParameters.filterRestaurants;
    }

    if (requestParameters.filterTarget !== undefined) {
      queryParameters["filter[target]"] = requestParameters.filterTarget;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/tags`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IndexTagResponseFromJSON(jsonValue)
    );
  }

  /**
   * Index tags.
   */
  async indexTags(
    requestParameters: IndexTagsRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IndexTagResponse> {
    const response = await this.indexTagsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Show tag by id.
   */
  async showTagRaw(
    requestParameters: ShowTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ShowTagResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling showTag."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("bearerAuth", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api/tags/{id}`.replace(
          `{${"id"}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ShowTagResponseFromJSON(jsonValue)
    );
  }

  /**
   * Show tag by id.
   */
  async showTag(
    requestParameters: ShowTagRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ShowTagResponse> {
    const response = await this.showTagRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
