/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  RegisterRequest,
  RegisterResponse,
  ValidationErrorsResponse,
} from "../models";
import {
  LoginRequestFromJSON,
  LoginRequestToJSON,
  LoginResponseFromJSON,
  LoginResponseToJSON,
  LogoutResponseFromJSON,
  LogoutResponseToJSON,
  RegisterRequestFromJSON,
  RegisterRequestToJSON,
  RegisterResponseFromJSON,
  RegisterResponseToJSON,
  ValidationErrorsResponseFromJSON,
  ValidationErrorsResponseToJSON,
} from "../models";

export interface LoginOperationRequest {
  loginRequest: LoginRequest;
}

export interface RegisterOperationRequest {
  registerRequest: RegisterRequest;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * Login user.
   * Login user.
   */
  async loginRaw(
    requestParameters: LoginOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    if (
      requestParameters.loginRequest === null ||
      requestParameters.loginRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "loginRequest",
        "Required parameter requestParameters.loginRequest was null or undefined when calling login."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/login`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LoginRequestToJSON(requestParameters.loginRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    );
  }

  /**
   * Login user.
   * Login user.
   */
  async login(
    requestParameters: LoginOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LoginResponse> {
    const response = await this.loginRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Logout user.
   * Logout user.
   */
  async logoutRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<LogoutResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/logout`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LogoutResponseFromJSON(jsonValue)
    );
  }

  /**
   * Logout user.
   * Logout user.
   */
  async logout(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<LogoutResponse> {
    const response = await this.logoutRaw(initOverrides);
    return await response.value();
  }

  /**
   * Register user.
   * Register user.
   */
  async registerRaw(
    requestParameters: RegisterOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<RegisterResponse>> {
    if (
      requestParameters.registerRequest === null ||
      requestParameters.registerRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "registerRequest",
        "Required parameter requestParameters.registerRequest was null or undefined when calling register."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/register`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RegisterRequestToJSON(requestParameters.registerRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegisterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Register user.
   * Register user.
   */
  async register(
    requestParameters: RegisterOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<RegisterResponse> {
    const response = await this.registerRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
