/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 * Discount resource object
 * @export
 * @interface Discount
 */
export interface Discount {
  /**
   *
   * @type {number}
   * @memberof Discount
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Discount
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof Discount
   */
  target: string | null;
  /**
   *
   * @type {string}
   * @memberof Discount
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Discount
   */
  description: string | null;
  /**
   *
   * @type {number}
   * @memberof Discount
   */
  amount: number | null;
  /**
   *
   * @type {number}
   * @memberof Discount
   */
  percent: number | null;
}

/**
 * Check if a given object implements the Discount interface.
 */
export function instanceOfDiscount(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "id" in value;
  isInstance = isInstance && "type" in value;
  isInstance = isInstance && "target" in value;
  isInstance = isInstance && "title" in value;
  isInstance = isInstance && "description" in value;
  isInstance = isInstance && "amount" in value;
  isInstance = isInstance && "percent" in value;

  return isInstance;
}

export function DiscountFromJSON(json: any): Discount {
  return DiscountFromJSONTyped(json, false);
}

export function DiscountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Discount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    type: json["type"],
    target: json["target"],
    title: json["title"],
    description: json["description"],
    amount: json["amount"],
    percent: json["percent"],
  };
}

export function DiscountToJSON(value?: Discount | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    type: value.type,
    target: value.target,
    title: value.title,
    description: value.description,
    amount: value.amount,
    percent: value.percent,
  };
}
