/* tslint:disable */
/* eslint-disable */
/**
 * imperia-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import type { Banquet } from "./Banquet";
import {
  BanquetFromJSON,
  BanquetFromJSONTyped,
  BanquetToJSON,
} from "./Banquet";

/**
 * Show banquet response object.
 * @export
 * @interface ShowBanquetResponse
 */
export interface ShowBanquetResponse {
  /**
   *
   * @type {Banquet}
   * @memberof ShowBanquetResponse
   */
  data: Banquet;
  /**
   *
   * @type {string}
   * @memberof ShowBanquetResponse
   */
  message: string;
}

/**
 * Check if a given object implements the ShowBanquetResponse interface.
 */
export function instanceOfShowBanquetResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "data" in value;
  isInstance = isInstance && "message" in value;

  return isInstance;
}

export function ShowBanquetResponseFromJSON(json: any): ShowBanquetResponse {
  return ShowBanquetResponseFromJSONTyped(json, false);
}

export function ShowBanquetResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ShowBanquetResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: BanquetFromJSON(json["data"]),
    message: json["message"],
  };
}

export function ShowBanquetResponseToJSON(
  value?: ShowBanquetResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: BanquetToJSON(value.data),
    message: value.message,
  };
}
